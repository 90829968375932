:root {
    --main_color: #f38321;
    --top_navbar: #f6f6f6;
    --main_navbar: #efefef;
    --main_navbar_button_text_color: #b4b4b4;
    --main_list_item_trash_color: #959595;
    --button_height: 40px;
    --button_normal_container_height: 60px;
    --button_width: 150px;
    --button_normal_margin: 15px;
    --button_normal_fontsize: 16px;
    --button_border_radius: 2px;
    --button_color: #ffffff;
    --add_save_menu_item_list_bottom: 12px;
    --input_normal_horizontal_padding: 10px;
    --margin_menu_normal_horizontal: 16px;
    --margin_menu_normal_vertical: 10px;
    --padding_menu_normal_horizontal: 14px;
    --padding_menu_normal_external_horizontal: 16px;
}

@media screen and (max-width: 1200px) {
    .add-button {
        font-size: 12px;
    }
}

@media screen and (max-width: 992px) {
    .add-button {
        font-size: 10px;
    }
}

@media screen and (max-width: 768px) {
    .add-button {
        font-size: 8px;
    }
    
}

@media screen and (max-width: 576px) {

    .add-button {
        font-size: 6px;
    }
}
@media screen and (max-width: 560px) {

 .create_new_shop_container{
    display: none;
 }
 .top_nav_profile_container{
        display: none;
 }
 .top_nav_container_shopTilte{
display: none;
 }
.main_nav_container{
      display: none;
}
.container-main-nav{
       display: none;
border: none;

}
.container-main-manu{
       display: none;

}

.container-drawer-icon{
    display: block;
    margin-right: 13px;
}
.container-drawer-icon i{
    font-size: 25px;
}
/* .c-div{
    display: none;
    
} */
.s-div{
      display: none; 
}
.mobile-s-div{
    display: block;
    /* width: 100%; */
   
    padding-bottom: 10px;
}
.container-menu-status{
    display: block;
     height:100%;
        height:  calc(100vh - 100px); 
    
        padding-bottom: 10px;

}
.Menu-div_countainer-component{
     display: none;
}
.mobile-container-noOption{

position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center; 
}
.list_header_container h6:first-of-type {
 width: 50%;
 font-size: 15px;
}
  .mobile-orders-container{
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    justify-content: space-between;

  }
  .mobile-orders-container-filters{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }
.mobile-orders-container-date{
display: flex;
margin-top: 10px;
justify-content: space-between;
}
.mobile-orders-container1{
display: flex; 
height: 160px;

justify-content: space-between;
}

.mobile-orders-container-orderBox{
 display: flex;
 flex: 1;
 padding-top: 10px;
 padding-bottom: 10px;
 flex-direction: column;
 justify-content: space-between;
margin-bottom: 15px;
background-color:#fffafc
;
  border-radius: 3px;
   box-shadow: 3px 3px 4px rgb(218, 216, 216), 0px 0px 2px rgb(255, 254, 249);
}

.mobile-show-order-status{
     width: 6px; 
       margin-bottom: 15px; 
box-shadow: 3px 3px 4px rgb(218, 216, 216), 0px 0px 2px rgb(255, 254, 249);
}

.mobile-orders-container-first-row{

    display: flex;
   flex-direction: column;

   /* margin-bottom: 5px; */
}
.mobile-orders-container-first-row-details,.mobile-orders-container-first-row-labels{
    display: flex;
    justify-content: space-between;
     
}
.mobile-orders-container-first-row-details p,.mobile-orders-container-first-row-labels p{
    margin: 0;
    flex: 1;
    font-size: 13px;
    text-align: center;
   
}
.mobile-orders-container-first-row-details p{
    color: rgb(138, 12, 14);
    margin-top: 6px;
    font-weight: 500;
}
.common-styles{
  height: calc(100vh - 100px); 
}
.mobile-orders-container-first-row-details div{
    flex: 1;
}
.mobile-orders-container-first-row-details div p{
    margin: 0;
}
.mobile-overview-container-courses{ display: flex;
  justify-content: space-between;
  width: 100%;
margin-top: 16px;}
  .mobile-overview-container-courses button{
    height: 40.2px;
 border-radius: 3px;
 background-color: white;
 font-size: 13px;
 width: 85px;
   border:.5px solid #ccc ;
  }
   .mobile-overview-container-dates h6{
    margin-bottom:2px;
 
   }
  .mobile-overview-container-dates{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
  }
  .mobbile-container-result-button{
    width: 23%;
    display: flex;
    align-items: flex-end;
  }
   .countaining-div-11{
height: calc(100vh - 100px);


   }  
   .mobile-overview-container-sale-method{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .mobile-overview-container-any-sale-method{
    width: 100%;
border-radius: 5px;
  margin-bottom: 15px;
  background-color: white;
  height: 120px;
  box-shadow: 3px 3px 4px 3px rgb(218, 216, 216), 0px 0px 2px 3px rgb(255, 254, 249);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
   }
    .mobile-overview-container-any-sale-method h4, .mobile-overview-container-any-sale-method p{
        margin: 0;
    }
    .mobile-overview-container-any-sale-method div{
       display: flex;
       align-items: center;
       width: 100%;

       margin-top:20px;
       justify-content: space-between;
       padding: 0px 10px;
    }
     .mobile-overview-container-any-sale-method span{
        color: red;
     }
   .mobile-overview-container-sale-method-details{
    width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .mobile-overview-container-all-sections{
  
    display: flex;
   padding-bottom: 30px;
   position: relative;
    flex-direction: column;
    justify-content: space-between;
   }
   .mobile-overview-container1{
    display: flex;
    flex: 1;
    flex-direction: column;
   justify-content: space-between;

   }
   .mobile-overview-container-total-box{
    width: 90%;
height: 50px;

   }
   .mobile-overview-container-total-box h5 ,.mobile-overview-container-total-box p{
    margin: 0;
   }
   .mobile-overview-container-total-box div{
    height: 50px;
    position:fixed;
    bottom: 5px;
    display: flex;
justify-content: space-between;
    width: 98%;
    position: fixed;
    align-items: center;
      box-shadow: 4px 4px 4px 3px rgb(218, 216, 216), -2px -2px 2px 3px rgb(255, 254, 249);
   }
 .mobile-container-shop-list-dropdown{
width: 60%;
  display: flex;
  align-items: center;
margin-left: 13px;
 }
 .side-bar-first-div{
  margin-bottom: 0px;
   width: 100%;
  z-index: 222;
 }
  /* .mobile-container-workstation-tabs,.mobile-container-workstation-list{
  display: none;
 } */
  .mobile-container-workstation-tabs{
  display: none;
 }
 .sm-div2{
  margin-top: 25px;
  margin-bottom: 25px;
 }
 .mobile-container-save-button{
  width: 100%;
  height: 50px;
 }
 .mobile-container-save-button button{
  border: none;
  background-color: #f38321;
  width: 98%;
  height:50px;
  color: white;
  position: fixed;
  border-radius: 3px;
  bottom: 5px;
   box-shadow: 4px 4px 4px 5px rgb(218, 216, 216), -2px -2px 3px 4px rgb(255, 254, 249);
 }
 .mobile-container-login-page{


 width: 100%;
 height: 30px; 
 position: fixed;

 }
 .login_logo_container{
   width: 100%;
    /* background-image: url(../public//images/back_logo.jpg); */
    height:50%;
   padding-right: 100px;

    background-size: contain;
     background-repeat: no-repeat;
     background-position: center;
    
 }

  .login_inputs_container{
    width: 88%;
    margin-top: -30px;
  }
   .login_inputs_container input{
    height: 45px;
    border: 1px solid rgb(200, 200, 200);
    /* margin-bottom: 20px; */
   }
    .login_inputs_container button{
      margin-top: 40px;
      height: 50px;
      border-radius: 3px;
    }
    .mobile-container-logo{
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      .mobile-container-logo img{
        width: 120px;
        height: 120px;
        margin-left: 23px;
      }
      .mobile-container-noOrder-text{
        display: flex;

        flex: 1;
        height: 50%;
        position: absolute;
width:91%;
        align-items: center;
        justify-content: center;
      }
.ASOP-div1{
margin: 0;
}
.ASOP-div1-input-text{
  margin-top: 30px;
  margin-bottom: 30px;
}
.div_submenu_add_container{
position: absolute;
top: 0;
bottom: 0;
right: 0;
left: 0;
  display: flex;
  flex: 1;
  align-items: center;

}
/* .ddd{
  background-color: #bfebfa;
} */
.mobile-container-sync-box{
  position: absolute;
  z-index: 333333;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

   display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-workstion-container-item{
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
   background-color: #fffafc;
   margin-bottom: 10px;
    border-radius: 3px;
  padding: 5px;
  flex: 1 0 100%;
    box-shadow: 3px 3px 2px  rgb(218, 216, 216), 0px 0px 2px  rgb(255, 254, 249);
}
.mobile-workstion-container-item p{
  margin: 0;
 color: rgb(76, 75, 75);
}
.mobile-workstion-container-item p:first-child{
  width: 70%;
}
.mobile-workstation-container-checkbox{

  display: flex;
  justify-content: space-between;
}
.mobile-workstation-container-checkbox div{
  width: 48%;
}
.mobile-workstation-container-div{
  /* border: 1px solid red; */
  overflow: hidden;
}
 .mobile-workstation-container-div button{
  height: 60px;
  min-width: 60px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  border-radius: 3px;
  cursor: pointer;
 }
 .mobile-workstation-container-details-and-delete-box{
  display: flex;

 }
}
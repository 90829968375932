:root {
    --main_color: #f38321;
    --top_navbar: #f6f6f6;
    --main_navbar: #efefef;
    --main_navbar_button_text_color: #b4b4b4;
    --main_list_item_trash_color: #959595;
    --button_height: 40px;
    --button_normal_container_height: 60px;
      --button_normal_container_height1: 56.4px;
    --button_width: 150px;
    --button_normal_margin: 15px;
    --button_normal_fontsize: 16px;
    --button_border_radius: 2px;
    --button_color: #ffffff;
    --add_save_menu_item_list_bottom: 12px;
    --input_normal_horizontal_padding: 10px;
    --margin_menu_normal_horizontal: 16px;
    --margin_menu_normal_vertical: 10px;
    --padding_menu_normal_horizontal: 14px;
    --padding_menu_normal_external_horizontal: 16px;
    --normal_margin_top: 50px;
    --normal_margin_bottom: 50px;
    --normal_padding_left: 10px
}

.margin_menu_normal_horizontal {
    margin-left: var( --margin_menu_normal_horizontal);
    margin-right: var( --margin_menu_normal_horizontal);

}

.images-style {
    height: 100%;
    object-fit: contain;
    width: 100%;

}

.create_shop_header_container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7%;
    margin-bottom: 3%;
    border-bottom-color: #e0e0e0;
    border-bottom-width: 0.3px;
    border-bottom-style: solid;

}

.create_shop_inputs_container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin: 0 0 7% 0;
}

.create_shop_inputs_container form {
    width: 100%;
    height: 100%;
}

.vat-container-checkbox-div {
    width: 90%;
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin: 50px auto;
}

.vat-container-checkbox-div h6 {
    margin: -16px 0px 8px 21px;
}

.create_shop_inputs_container input ,
.create_shop_inputs_container_1 input{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin: 1% 0 0 0;
    padding: 5px;
    border: 0.3px solid #e0e0e0;
    outline: none;
}

.create_shop_inputs_container input:hover,
.create_shop_inputs_container input:focus ,
.create_shop_inputs_container_1 input:hover,
.create_shop_inputs_container_1 input:focus 
{
    border: 1px solid var(--main_color);
}

.create_shop_button_container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_shop_button_container button {
    width: 90%;
    height: 70%;
    background-color: var(--main_color);
    border: none;
    border-radius: 4px;
    color: white;
}


/* h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
} */

.submenuItemEdit-amountdiv,
.submenuItemEdit_firstGroupDropDown,
.submenuItemEdit_secoundGroupDropDown {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 50px 0px 50px 0px;
}

.WSE-container-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 40px 0px;
}

.workStation-checkbox-div {
    margin-top: 52px;
    margin-bottom: 48px;
}

.WSE-container-div2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 40px 0px;
}

input[type="checkbox"] {
    opacity: 0;
}

.Menu-div_countainer-component {
    width: 75%;
    position: relative;
}

.Menu-div-countainer-CategoryEdit_component {
    height: 100%;
    /* overflow-y: scroll; */
}

input[type="checkbox"]+label {
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 13px;
    height: 13px;
    border: .5px solid black;
    position: relative;
    right: 13px;
    bottom: 2px;
    background-color: white;
    border-radius: 2px;
}

input[type="checkbox"]:checked+label::before {
    background: var(--main_color);
    content: '\002713';
    font-size: 11px;
    position: relative;
    top: -8px;
    left: -.7px;
    border-radius: 2px;
    border: 1px solid var(--main_color);
    color: white;
    padding: 0px 1.5px 0px 1.5px;
}

.checkmark {
    position: absolute;
    top: 4px;
    /* left: -24px; */
}

.tap_nav_container {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--top_navbar);
    padding: 0 2%;
}

.all_content_container {
    padding: 0 1% 0 1%;
    /* border:1px solid red; */
}

.submenuItemEdit_button_save {
    width: 81.5%;
    background-color: rgb(255, 0, 191);
    height: 45px;
    text-align: center;
    color: white;
    font-size: 20px;
    position: fixed;
    bottom: 10px;
    margin-left: -1px;
}

.submenuItemEdit-div1 {
    width: 22%;
}

.submenuItemEdit-div2 {
    width: 25%;
}

.submenuItemEdit-multiselect-div {
    width: 29.63%;
}

.submenuItemEdit-multiselect {
    margin-top: -9px;
}

.submenuItemEdit-h6 {
    margin: 0px 10px -20px 20px;
}

.submenuItemEdit-input1 {
    height: 29px;
    width: 87%;
    font-size: 18px;
    margin: 12px 10px 5px -9px;
    border: none;
    outline: none;
    padding: 18px 0px 21px 13px;
    border: 1px solid rgb(245, 239, 239);
    box-shadow: 1px 1px 5px rgb(238, 231, 231);
}
.top_nav_container_shopTilte{
       display: flex;
          justify-content: center;
          align-items: center;
          color: grey;
}

.submenuItemEdit--input2 {
    height: 25px;
    width: 87%;
    margin-bottom: 14px;
    margin: 7px 10px 13px 8px;
    border: none;
    outline: none;
    padding: 14px 0px 16px 13px;
    border: 1px solid rgb(238, 231, 231);
    box-shadow: 1px 1px 5px rgb(228, 225, 225);
}

.submenuItemEdit-h6-printer {
    margin: -6px 0px 11px 0px;
}

.create_new_shop_container {
    width: 160px;
    height: 40px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.create_new_shop_container button {
    width: 100%;
    height: 100%;
    background-color: var(--main_color);
    color: white;
    border-radius: 5px;
    border-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5%;
}

.top_nav_profile_container {
    display: flex;
    height: 100%;
    align-items: center;
  
   
}

.top_nav_profile_container span {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.main_nav_container {
    width: 100%;
    height: var(--button_normal_container_height);
    display: flex;

    align-items: center;
    background-color: var(--main_navbar);
}

.main_nav_buttons {
    min-width: var(--button_width);
    height: 100%;
    margin-right: var(--button_normal_margin);
    background-color: var(--main_color);
    color: white;
    border-radius: var(--button_border_radius);
    border: solid var(--main_navbar_button_text_color);
}

.storys {
    transition: .5s all ease-out;
}

.main_nav_buttons:hover {
    border: 1px solid var(--main_color);
}

.menu_collapse_item {
    height: 40px;
    border: 1px solid var(--main_color);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.dropdown-div {
    /* border: 1px solid rgb(203, 200, 200); */
    /* box-shadow: 1px 1px 1px rgb(194, 191, 191); */
    height: 140px;
    text-align: center;
    margin: 0 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--main_navbar);
}
.dropdown-div-ownermenu{
     height: 90px;
    text-align: center;
    margin: 0 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--main_navbar);
}
.home_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background-color: aquamarine; */
}


/* .container_sidebar_title{
    direction: rtl;
    transform: rotate(360deg); */


/* width:10px;  
}  */

.sidebar_title {
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 220px;
    height: 40px;
    margin-bottom: 10px;
    ;
}

.sidebar_title:hover,
.user-sidebar-title:hover {
    background-color: var(--main_navbar);
}

.sidebar_li_subtitle {
    margin-bottom: 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: blue;
    width: 100%;
}

.sidebar_li_subtitle div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95px;
}

.sidebar_li_subtitle:hover {
    background-color: var(--main_navbar);
}

.c-div {
    display: flex;
    z-index: 2;
    width: 100%;
   
}
.container-menu-status{
    height:  calc(100vh - 160px); 
}

.s-div {
    width: 25%;
    height: 100%;
    position: relative;
    /* padding-bottom: 135px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* overflow-y: scroll; */
    border-left: 0.3px solid var(--main_navbar_button_text_color);
    /* box-shadow: -22px 0px 17px -15px rgb(235, 231, 231), 18px 0px 15px -15px rgb(219, 214, 214); */
}
.mobile-s-div {
     width: 100%;
    height: 100%;
    
    position: relative;
    /* padding-bottom: 135px; */
    display: none;
    flex-direction: column;
    justify-content: space-between;
    /* overflow-y: scroll; */
    border-left: 0.3px solid var(--main_navbar_button_text_color);
}

.categort_header_container {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
    background-color: var(--main_navbar);  
     
      /* border:.1px solid var(--main_navbar_button_text_color); */
} .categort_header_container h6{
    margin: 0;
    width:100%;
    text-align: center;
 
}
.categort_header_container div:first-child{
align-items: center;
    width:99%;
    flex: 1;
    display: flex;
    justify-content: space-between;
}
.category-list-header-cotainer-switch-buttons{
 background-color:white ;
    border-radius: 19px;
    padding: 2px;
    height: 39px;
    align-items: center;
    width:99%; 
     display: flex;
 
    justify-content: space-between;
/* border: 1px solid rgb(213, 212, 212); */
margin-bottom: 1px;
     box-shadow: 1px 1px 1px rgb(214, 213, 213), -1px -1px 1px rgb(214, 213, 213); 
}
.categort_header_container button{
    width: 48%;
height: 100%;
border-radius: 19px;
border: none;

background-color:white ;

} 
.product_header_container{
    height: 93%;
    width: 33% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 0 16px; */
    background-color: var(--main_navbar); 

}
.product-list-header-cotainer-switch-buttons{
 background-color:white ;
    border-radius: 19px;
    padding: 2px;
    height: 39px;
    align-items: center;
    /* width:10%;  */
     display: flex;
    justify-content: space-between;

margin-bottom: 1px;
     box-shadow: 1px 1px 1px rgb(214, 213, 213), -1px -1px 1px rgb(214, 213, 213); 
}

.product_header_container button{
       width: 48%;
height: 100%;
border-radius: 19px;
border: none;

background-color:white ;

}
.product_header_container i{
    margin: 0 !important;
    margin-right: 5px !important;
}
.ganaral_app_setting_counteine_tabel_name {
    height: 40px;
}

.menu-div {
    width: 100%;
    /* box-shadow: 1px 1px 1px rgb(194, 191, 191); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    border: solid rgb(203, 200, 200);
}

.menu-div:hover {
    border: solid var(--main_color);
}


/* .general-menu-div:focus{
    border: solid var(--main_color);
    border: 2px solid red;
    background-color: red;
} */

.category_list_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0 0;
    padding: 0 16px;
}

/* .categort_header_container div {
    height: 100%;
    width: 85%;
    background-color: var(--main_navbar);
    display: flex;
    align-items: center;
    padding: 0 10px;
} */

.appSetting-orderProccess-save {
    width: 80%;
    background-color: rgb(255, 0, 191);
    height: 45px;
    text-align: center;
    color: white;
    font-size: 20px;
    position: fixed;
    bottom: 10px;
}

.list_header_container {
    background-color: var(--main_navbar);
    height: 47px;
    display: flex;
    margin: 0 16px;
    padding: 0 14px;
    align-items: center;


}

.list_header_container-general {
    background-color: var(--main_navbar);
    height: 40px;
    display: flex;
    padding: 0 14px;
    align-items: center;
    width: 100%;
}

.menu_list_item_container {
    height: 47px;
    display: flex;
    margin: 10px 16px;
    align-items: center;
    justify-content: space-between;
}

.menu_list_title_container {
 
    width: 100%;
    height: 100%;
    margin: 0 15px 0 0;
    padding: 10px 10px 12px 10px;
    display: flex;
   
    justify-content: space-between;
    background-color: var(--main_navbar);
     align-items: center;
}
/* .menu_list_title_container h6{
    margin: 0;
} */
.menu_list_title_container i {
    /* margin-right: 15px; */
    cursor: pointer;
    color: var(--main_list_item_trash_color);
}
.menu_list_title_container-first-section{
    display: flex;
    flex: 1;
    justify-content: space-between;
}
.menu_list_field-container{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}
.menu_list_field-container h6{
    margin: 0;
    flex: 1;
     font-size: 14px;
    
}
.menu_list_field-container div{
    flex: 1;
    display: flex;
      color: grey
}
.menu_list_field-container_number_2{
     display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}
.menu_list_field-container-number-3{
      display: flex;
                width: 100%;
                align-items: center;
}
.menu_list_field-container-number-3 i{
    margin-right: 5px;
}
.menu_list_field-container-number-3 h6:first-child{
flex: 1;
margin-right: 5px;
margin: 0;
}
.menu_list_field-container-number-3 div{
    flex: 5;
    display: flex;align-items: center;
    justify-content: space-between;
}

.menu_list_field-container-number-3 div h6{
    flex: 1;
    margin: 0;
}
.menu_list_field-container_number_2 h6{
    margin: 0;
    font-size: 14px;
}
.menu_list_field-container_number_2 h6:first-child{
flex: 1;
    margin-right: 10px;
}
.menu-div i {
    cursor: pointer;
    color: var(--main_list_item_trash_color);
    font-size: 16px;
}

.menu_list_item_buttons_container {
    display: flex;
    height: 100%;
}

.menu_list_item_buttons_container button {
    width: var(--button_width);
    height: 100%;
    margin: 0 15px 0 0;
    border-radius: var(--button_border_radius);
    background-color: white;
    border: 1px solid var(--main_navbar_button_text_color);
}

.menu_list_item_buttons_container button:hover {
    /* color: var(--main_color); */
    border: 1px solid var(--main_color);
}

.list_header_container div {
    width: 100%;
    text-align: center;
}

.list_header_container-general div {
    width: 100%;
    text-align: center;
}

.list_header_container i {
    margin: 0 20px 0 0;
}

.ASOP-countainer-div {
    /* width: 95%; */
    height: 100%;
    margin: var(--margin_menu_normal_vertical) var(--margin_menu_normal_horizontal);
}

.app-setting-payment {
    margin: 53px 0px -22px 20px;
}


/* .payment {
    margin-left: 4px;

} */

.b-div {
    width: 100%;
    /* box-shadow: 1px 0 8px rgb(253, 247, 247), -4px 0 6px rgb(255, 251, 251); */
    height: 98%;
    overflow-y: scroll;
}

.general-div {
    width: 100%;
    background-color: var(--main_navbar);
    height: 40px;
    text-align: center;
    color: white;
    font-size: 20px;
}

.save_menu_button_container {
    height: var(--button_height);
    position: fixed;
    bottom: 10px;
    right: 2%;
    left: 2%;
    padding-left: 220px;
    z-index: 12345;
}

.add_category_main_container {
    /* position: fixed; */
    display: flex;
    align-items: center;
    height: var(--button_normal_container_height1);
    background-color: var(--main_navbar);
    /* width: 95%; */
  
    /* border: 1px solid red; */
}

#save_button_div {
    height: var(--button_height);
    width: 100%;
}

#add_category_container {
    /* height: 100%; */
    margin-right: 75%;
    padding-left: 16px;
    padding-right: 32px;
}

.add-button {
    width: 15%;
    height: 100%;
    background-color: var(--main_color);
    border: 0.3px solid var(--main_color);
    border-radius: var(--button_border_radius);
    color: var(--button_color);
    font-size: 14px;
    padding: 0;
}

.addcategory-input {
    padding: 0 var(--input_normal_horizontal_padding);
    width: 65%;
    height: 100%;
    font-size: var(--button_normal_fontsize);
    outline: none;
    border: 1px solid var(--main_navbar_button_text_color);
    /* box-shadow: 1px 1px 1px rgb(200, 197, 197); */
}

#add_category_button_input_container {
    display: flex;
    justify-content: space-between;
    height: var(--button_height);
    align-items: center;
    width: 100%;
    margin-top: 0px;
}

#category_input_add_conatiner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.addcategory-input:focus {
    border: 1px solid var(--main_color);
}

#save_button_menu {
    background-color: var(--main_color);
    color: white;
    height: 100%;
    width: 100%;
    border-radius: 1px;
    border: none;
    cursor: pointer;
    
}

.addbox_container {
    width: 280px;
    /* border: 1px solid rgb(203, 200, 200); */
    /* box-shadow: 1px 1px 1px rgb(194, 191, 191); */
    display: flex;
    bottom: 10px;
    padding: 8px;
    left: 266px;
    position: fixed;
    justify-content: space-between;
    background-color: white;
    /* background-color: rgb(146, 15, 15); */
    /* margin-top:200px; */
}

.create_shop_header_container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7%;
    margin-bottom: 3%;
    border-bottom-color: #e0e0e0;
    border-bottom-width: 0.3px;
    border-bottom-style: solid;
}

.create_shop_inputs_container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin: 0 0 7% 0;
}
.create_shop_inputs_container_1{
     width: 90%;
    height: 7%;
    margin: 0 0 7% 0;
}
.create_shop_inputs_container_1 input{
    width:100%;
}
.create_shop_inputs_container form {
    width: 100%;
    height: 100%;
}

.create_shop_inputs_container input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin: 1% 0 0 0;
    padding: 5px;
    border: 0.3px solid #e0e0e0;
}

.create_shop_button_container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_shop_button_container button {
    width: 90%;
    height: 70%;
    background-color: var(--main_color);
    border: none;
    border-radius: 4px;
    color: white;
}


/* h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
} */

.tap_nav_container {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--top_navbar);
    padding: 0 2%;
}

.submenuItemEdit_div9 {
    margin-bottom: 200px;
}

.submenuItemEdit_button_save {
    width: 81.5%;
    background-color: rgb(255, 0, 191);
    height: 45px;
    text-align: center;
    color: white;
    font-size: 20px;
    position: fixed;
    bottom: 10px;
    margin-left: -1px;
}

.submenuItemEdit-input {
    width: 100%;
    height: var(--button_height);
    outline: none;
    border: .3px solid var(--main_navbar_button_text_color);
}

.submenuItemEdit-input:hover,
.submenuItemEdit-input:focus {
    border: 1px solid var(--main_color);
}

.submenuItemEdit-div1 {
    width: 20%;
}

.submenuItemEdit-multiselect {
    margin-top: -9px;
}


/* .submenuItemEdit-h6 {
    margin: 0px 10px -20px 20px;
} */
.container-login-page{
   background-color: #73ccdd; 
  background-image: url(../public//images/cooking_banner_food_top_view_free_space_your_text_187166_3284.jpg);
background-repeat: no-repeat;
background-size: cover;
}

.submenuItemEdit-input1 {
    height: 29px;
    width: 87%;
    font-size: 18px;
    margin: 12px 10px 5px -9px;
    border: none;
    outline: none;
    padding: 18px 0px 21px 13px;
    border: 1px solid rgb(245, 239, 239);
    box-shadow: 1px 1px 5px rgb(238, 231, 231);
}

.submenuItemEdit--input2 {
    height: 25px;
    width: 87%;
    margin-bottom: 14px;
    margin: 7px 10px 13px 8px;
    border: none;
    outline: none;
    padding: 14px 0px 16px 13px;
    border: 1px solid rgb(238, 231, 231);
    box-shadow: 1px 1px 5px rgb(228, 225, 225);
}

.submenuItemEdit-h6-printer {
    margin: -6px 0px 11px 0px;
}

.create_new_shop_container {
    width: 160px;
    height: 40px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.create_new_shop_container button {
    width: 100%;
    height: 100%;
    background-color: var(--main_color);
    color: white;
    border-radius: 5px;
    border-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5%;
}

.top_nav_profile_container {
    display: flex;
    height: 100%;
    align-items: center;
}

.top_nav_profile_container span {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.login_logo_container {
    /* background-image: url(../public//images/back_logo.jpg); */
    width: 40%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: var(--main_color);
}

.login_inputs_container {
    width: 40%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* border:1px solid red; */
    justify-content: center;
}

.login_inputs_container h6 {
    margin-bottom: 5px;
    color: white;
}

.login_inputs_container button {
    margin-top: 70px;
    height: var(--button_height);
    border-style: none;
    background-color:rgb(134, 24, 24);
    color: var(--button_color);
}

.login_inputs_container input {
    margin-bottom: 20px;
    height: 40px;
    padding: 0 10px;
    border: 0.3px solid var(--main_navbar_button_text_color);
    border-radius: var(--button_border_radius);
}

.login_inputs_container input:focus {
    border: 0px solid;
    outline: 1px solid var(--main_color);
}

.main_nav_buttons_container {
    height: var(--button_height);
    width: 96%;
    display: flex;
    margin: auto;
}

.settings_tab_buttons_container {
    height: var(--button_height);
    margin: var(--margin_menu_normal_horizontal);
}

.menu_collapse_item {
    height: 40px;
    border: 1px solid var(--main_color);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.dropdown-div {
    /* border: 1px solid rgb(203, 200, 200); */
    /* box-shadow: 1px 1px 1px rgb(194, 191, 191); */
    height: 140px;
    text-align: center;
    margin: 0 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--main_navbar);
}

.sidebar_title {
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 220px;
    height: 40px;
    margin-bottom: 10px;
}

.sidebar_title:hover {
    background-color: var(--main_navbar);
}

.sidebar_li_subtitle {
    margin-bottom: 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: blue;
}

.sidebar_li_subtitle:hover {
    background-color: var(--main_navbar);
}

.color-first-input {
    width: 95%;
    height: var(--button_height);
    outline: none;
    border: .3px solid var(--main_navbar_button_text_color);
}

.AS-COLOR-first-h6 {
    font-size: 15px;
}

.color-first-input {
    width: 100%;
}

.color-h6 {
    margin-bottom: 12px;
    font-size: 15px;
}

.colour-div {
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}

.category_list_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0 0;
    padding: 0 16px;
}



.appSetting-orderProccess-save {
    width: 80%;
    background-color: rgb(255, 0, 191);
    height: 45px;
    text-align: center;
    color: white;
    font-size: 20px;
    position: fixed;
    bottom: 10px;
}

.list_header_container div {
    width: 100%;
    text-align: center;
}

.list_header_container i {
    margin: 0 20px 0 0;
}

.category-0ption-general-div {
    margin-top: -20px;
}

.poe-div1 {
    margin: 30px 0px 0px 0px;
    width: 100%;
}

.app-setting-payment {
    margin: 53px 0px -22px 20px;
}

.payment {
    margin-left: 3px;
}

.b-div {
    width: 100%;
    /* box-shadow: 1px 0 8px rgb(253, 247, 247), -4px 0 6px rgb(255, 251, 251); */
    height: 98%;
    overflow-y: scroll;
}

.general-div {
    width: 100%;
    background-color: var(--main_navbar);
    height: 40px;
    text-align: center;
    color: white;
    font-size: 20px;
}

.save_menu_button_container {
    height: var(--button_height);
    position: fixed;
    bottom: 10px;
    right: 2%;
    left: 2%;
    padding-left: 220px;
}

.save_menu_button_container div {
    height: 100%;
    margin-left: 25%;
    padding-left: 16px;
    padding-right: 32px;
}

.save_menu_button_container button {
    background-color: var(--main_color);
    color: white;
    height: 100%;
    width: 100%;
    border-radius: 1px;
    border: none;
}

.COLOUR-SketchPicker-div {
    position: absolute;
    top: 60px;
    left: 13px;
    z-index: 3;
}

.countaining-div {
    width: 100%;

    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.countaining-groups-div{
      width: 100%;
    /* box-shadow: 1px 0 8px rgb(253, 247, 247), -4px 0 6px rgb(255, 251, 251); */
    height: calc(100vh - 160px); 
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.common-styles {
    display: flex;
    z-index: 2;
    width: 100%;

}
.orders-container-div{
      height: calc(100vh - 160px); 
}
.container-components-div {
    width: 100%;
    height: 100%
}

.dashborard-index-countainer-div {
    display: flex;
    width: 100%;
    height: 100%;
}

.index-countainer-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.index-h1 {
    text-align: center;
    color: red;
    display: block
}
.menu_list_header_container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
   
}
.menu_list_header_container-12{
       height: 100%;
  
    overflow-x: hidden;
}

.menu_list_header_container_tabel_list {
    /* height: 100%; */
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;

}
.sale_overview_container_calander{
width: 100%;
height: 100px;
  display: flex;
align-items:flex-end;
justify-content: space-between; 
}
.sale_overview_container{
display: flex;
flex-direction: column;
justify-content: space-between;


}

.sale_overview_container_calander h6,.sale_overview_container_calander h5 {
  margin: 0;
  display: flex;
  padding-bottom: 2px;
  align-items: center;
 /* padding-left: 10px;
 color: #388e3c; */
 /* border: 1px solid blue; */
}
.sale_overview_container_calander_courses{
  display: flex;
  justify-content: space-between;
  width: 31%;
}
.sale_overview_container_from_to{
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

}
.sales-container-result-button{
     width: 18%;
 height: 40px;
 display: flex;
 align-items: flex-end;

}
.sales-container-result-button button{
    border:.5px solid #ccc ;
    width: 100% ;
    height: 100%;
    background-color: red;
    color: white;
    border-radius: 3px;
}

.date_picker_sales{ 
    height: var(  --button_height);
  width: 100%;
  outline: none;
  border:.5px solid #ccc ;
  border-radius: 3px;
  padding-left: 2%
}

.sale_overview_container_Total{
    width: 28%;
}
.sale_overview_container_Total div{
  height: 40px; 
   background-color: rgb(121, 177, 255);
    border-radius: 2%;
    display: flex;
    color: white;
    padding: 0px 5px;
    justify-content: space-between;
}
.sale_overview_container_calander_courses button{
 height: 40.2px;
 border-radius: 3px;
 background-color: white;
 font-size: 13px;
 width: 83px;
   border:.5px solid #ccc ;
 /* border: none; */
     /* border: .5px solid var(--main_navbar_button_text_color) */
}

.sales_h3_no_order{
    margin: 0;
    width: 40%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sale_overview_container_date{
    height: 76%;

display:flex;
flex-direction: column;
justify-content: space-between;
}
.sale_overview_container_choose_date_header_section{
    width: 100%;
 height: 46px;
 /* margin-bottom: 2px; */
display: flex;
justify-content: space-between;
background-color: red;
align-items: center;
color: white;
border-radius: 2px;
padding: 8px;
}
.sale_overview_container_choose_date_header_section_today_date{
        width: 100%;
 height: 46px;

display: flex;
justify-content: space-between;
background-color: white;
align-items: center;
border-radius: 2px;
color: rgb(118, 116, 116);
padding: 8px;
}
.sale_overview_container_choose_date_header_section_today_date p{
    margin: 0;
}
.sale_overview_container_choose_date_header_section p{
    margin: 0;
    font-size: 14px;
    width: 50%;
}
.sale_overview_date_picker{
    width: 100%;
    height: 50%;
}
.sale_overview_container_choose_date_main_section{
    width: 100%;
background-color: white;
}
.clander{
/* width: fit-content; */
height: fit-content;

padding:0px;
margin: 0;

}

.sale_overview_container_saleMethod{
    height: 175px;
    width: 100%;
    margin-top: 30px;display: flex;
    flex-direction: column;

}
.sale_overview_container_saleMethods{
display: flex;

   height: 138px;
}

.sale_overview_container_saleMethod h6{
    height:25px;
  margin: 0;
  display: flex;
  align-items: center;
 padding-left: 2px;
 margin-bottom: 8px;
}


.sale_overview_container_any_salemethod{
   width: 100%;
 background-color: white;
 padding: 8px 5px 13px 20px;
 display: flex;  width: 190px;
 flex-direction: column;
height:100%;
border-radius: 2px;
 justify-content:space-between ;
 margin-right: 10px;

 
}
.sale_overview_container_any_salemethod span{
    color: red;
}
.sale_overview_container_any_salemethod h5 
,.sale_overview_container_any_salemethod p{
    margin: 0;
}


.sale_overview_container_any_paymethod{
    width: 49.8%;
    background-color: white;
    height: 100%; padding: 8px 5px 13px 20px;
 display: flex;
 flex-direction: column;

 justify-content:space-between ;
}
.sale_overview_container_total_box{
 width: 27%;
height: 100%;

 display: flex;
 flex-direction: column;
 justify-content: flex-end;
}
.sale_overview_container_total{
    width: 100%;
    height: 87.5%;
    padding: 50px 30px 25px 30px;
    background-color: rgb(121, 177, 255);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sale_overview_container_total h6 ,.sale_overview_container_total h1,
.sale_overview_container_total h4{
    margin: 0;
}


.appSetting-payment {
    margin: 48px 0px 10px 0px;
}

.add_menu_list_item_main_container {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main_navbar);
margin:auto 0px auto var(--padding_menu_normal_external_horizontal) ;

}

.add_menu_list_item_main_container div {
    /* width: 100%; */
    height: var(--button_height);
    display: flex;
   
}

.add_menu_list_item_main_container input {
    /* margin: 0 var(--button_normal_margin) 0 0; */
    width: 26.5%;
    padding: 0 var(--input_normal_horizontal_padding);
    height: var(--button_height);
}

.add_menu_list_item_main_container button {
    width: var(--button_width);
    border: 0.3px solid var(--main_color);
    font-size: var(--button_normal_fontsize);
    background-color: var(--main_color);
    color: white;
    height: var(--button_height);
}

.add_menu_list_item_main_container button:hover {
    border: 0.3px solid var(--main_color);
}

.add_menu_list_item_main_container input:focus {
    border: 0px solid;
    outline: 1px solid var(--main_color);
}

.div_submenu_add_container_fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    /* right: calc(2vw + 35px); */
    border: 1px solid var(--main_color);
    bottom: var(--add_save_menu_item_list_bottom);
    background-color: var(--main_color);
    /* background-color: rgb(234, 179, 40); */
    width: var(--button_width);
    height: var(--button_height);
    border-radius: var(--button_border_radius);
    cursor: pointer;
    color: white;
    /* color: #555252; */
}

.div_submenu_add_container_fixed span {
    display: flex;
    background-color: green;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.div_submenu_add_container_fixed p {
    margin: 0 0 0 10px;
}

.sub_menu_list_popup_container {
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    position: absolute;
    z-index: 123456888;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: 30px;
}

.submenu_popup_buttons_list_container {
    background-color: white;
    height: 80%;
    width: 40%;
    border: 1px solid black;
    border-radius: 5;
    position: relative;
}

.product-submenu-list-popup-first-div {
    display: flex;
    flex-direction: column
}

.submenu_popup_list_container {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    padding: 0 0 40px 0;
    margin-top: 6px;
}

.submenu_popup_buttons_container {
    background-color: white;
    width: 100%;
    height: 60px;
}
.shop_group_container_save_button{
     background-color: white;
    width: 100%;
    height: 60px;
    padding: 0px 0px 25px 0px;
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.shop_group_container_save_button button{
width: 95.5%;
height:50px;
margin: auto;
background-color: var(--main_color);
border: none;
color: white;
}

.post-code-popup-buttons-container {
    background-color: white;
    width: 100%;
    height: 60px;
    height: 12%;
    width: 100%;
    text-align: center;
  
}

.drivers-popup-buttons-container {
    background-color: white;
    width: 100%;
    height: 60px;
    height: 12%;
    width: 100%;
    text-align: center;
}

.drivers-popup-buttons-container #popup_add {
    border-radius: var(--button_border_radius);
    height: var(--button_height);
    margin: auto;
    background-color: var(--main_color);
    color: white;
    width: var(--button_width);
    margin-left: 24px;
    border: 1px solid var(--main_color);
    border-radius: 4px,
}

.drivers-popup-buttons-container #popup_cancel {
    border-radius: var(--button_border_radius);
    width: var(--button_width);
    height: var(--button_height);
    border: 0.3px solid var(--main_navbar_button_text_color);
    color: var(--main_navbar_button_text_color);
    background-color: white;
}

.submenu_popup_buttons_container div {
    display: flex;
    height: 100%;
    margin-left: var(--margin_menu_normal_horizontal);
    padding: 0 var(--padding_menu_normal_horizontal);
    align-items: center;
    justify-content: flex-end;
    border-top: 0.3px solid var(--main_navbar_button_text_color)
}

.submenu_popup_buttons_container #popup_cancel {
    border-radius: var(--button_border_radius);
    width: var(--button_width);
    height: var(--button_height);
    border: 0.3px solid var(--main_navbar_button_text_color);
    color: var(--main_navbar_button_text_color);
    background-color: white;
}

.post-code-popup-buttons-container #popup_add {
    border-radius: var(--button_border_radius);
    height: var(--button_height);
    margin: auto;
    background-color: var(--main_color);
    color: white;
    width: 90%;
    /* margin-left: 24px; */
    border: 1px solid var(--main_color);
    border-radius: 4px,
}

.submenu_popup_buttons_container #popup_add {
    border-radius: var(--button_border_radius);
    width: var(--button_width);
    height: var(--button_height);
    margin: 0 0 0 var(--button_normal_margin);
    background-color: var(--main_color);
    color: white;
}

.product_submenu_popup_list_container {
    height: 60px;
    background-color: var(--main_navbar);
    display: flex;
    align-items: center;
    margin: var(--margin_menu_normal_vertical) var(--margin_menu_normal_horizontal);
    padding: 0 var(--padding_menu_normal_horizontal);
    justify-content: space-between;
    border-radius: var(--button_border_radius);
}
.shop_group_container_shops_list{
  overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    width: 100%;
    height: 100%;
    
}
.shop_groups_container_boyh_lists{
    width: 50%;
}

.shop_group_container_any_shop{
     height: 60px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    margin: 0px var(--margin_menu_normal_horizontal) var(--margin_menu_normal_horizontal) var(--margin_menu_normal_horizontal);
    padding: 0 0 0 var(--padding_menu_normal_horizontal);
    /* justify-content: space-between; */
    border-radius: var(--button_border_radius);
}
 .shop_group_container_any_shop p{
    margin: 0;
    margin-left: -18px;
 }
.shop_group_container_any_shop button{
     width: var(--button_width);
    height: var(--button_height);
    background-color: white;
    border: 0.3px solid var(--main_navbar_button_text_color);
    color: var(--main_navbar_button_text_color);
    border-radius: var(--button_border_radius); 
}
.shop_group_container_any_shop button:hover{
       border: 0.3px solid var(--main_color);
}

.product_submenu_popup_list_container button {
    width: var(--button_width);
    height: var(--button_height);
    background-color: white;
    border: 0.3px solid var(--main_navbar_button_text_color);
    color: var(--main_navbar_button_text_color);
    border-radius: var(--button_border_radius);
}

.product_submenu_popup_list_container button:hover {
    border: 0.3px solid var(--main_color);
}

.div-9-crust {
    width: 32.5%;
    margin: 50px 0px 280px 0px;
    height: 30px;
}

.employees-colors-div {
    width: 30%;
    margin: 31px 0px 90px 0px;
}

.div-7-crust {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin: 50px 0px 50px 0px;
}

.submenuEdit-titles {
    text-align: left;
    margin-bottom: 6px;
}

.loader_component_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 123456789999;
    /* background-color: rgba(0, 0, 0, 0.75); */
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
}

.loader_mini_component_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.div_submenu_add_container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.div_submenu_add_container span {
    display: flex;
    background-color: var(--main_color);
    justify-content: center;
    align-items: center;
    border-radius: var(--button_border_radius);
    color: white;
    font-size: 16px;
    cursor: pointer;
    height: var(--button_height);
    width: var(--button_width);
}

.div_submenu_add_container h4 {
    margin: 0 0 30px 0;
}

.div_submenu_add_container p {
    margin: 0 0 0 10px;
}

.countaining-div-submenu {
    width: 100%;
    /* box-shadow: 1px 1px 8px rgb(234, 228, 228), -4px 0 6px rgb(243, 237, 237); */
    height: 100%;
    /* margin-right: -200px; */
    position: relative;
    overflow-y: scroll;
    padding-bottom: 85px;
}

.countaining-subset-imployes {
    width: 20%;
    display: flex;
    justify-content: space-around;
    margin-right: 10px;
}

.subset-div-imployes {
    cursor: pointer;
    width: 50%;
    border: 1px solid rgb(195, 190, 190);
    text-align: center;
    border-radius: 10px;
    height: 40px;
    margin: 12px -10px 12px 40px;
    padding-top: 7px;
}

.i_trash-imployes {
    font-size: 35px;
    color: rgb(227, 49, 49);
    margin: 15px -15px 0px 0px;
    cursor: pointer;
}

.employees-checkbox {
    margin: 50px 0px 50px 0px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.employees-countainer-checkbox {
    margin: 90px 0px 80px 0px;
    background-color: red;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}


/* .image-item {
    width: 100%;
    height: 110px;
    border: 1px solid rgb(216, 213, 213);
    box-shadow: 1px 1px 1px rgb(232, 227, 227);
} */

.image-div {
    width: 100%;
    height: 110px;
    border: 1px solid rgb(216, 213, 213);
   
    /* box-shadow: 1px 1px 1px rgb(232, 227, 227); */
}

.imployees-image {
    width:100%;
    height: 110px;
    border: 1px solid rgb(216, 213, 213);
    box-shadow: 1px 1px 1px rgb(232, 227, 227);
}

.file-i {
    padding: 0px 0px 0px 0px;
    border: 1px solid rgb(235, 230, 230);
    /* box-shadow: 1px 1px 1px rgb(227, 221, 221); */
    width: 100%;
    /* margin-left: -17px; */
    margin-bottom: 10px;
}

.employees-image-input {
    width: 100%;
    padding: 1px 1px 0px 0px;
    border: 1px solid rgb(235, 230, 230);
    box-shadow: 1px 1px 1px rgb(227, 221, 221);
    margin: -1% 0% 6% 0%;
}

.employees-image-p {
    text-align: left;
}

.employee-edit-image {
    margin-bottom: 40px;
}

.bachgroundColor-div {
    width: 100%;
    border-bottom: 1px solid black;
    display: flex;
}

.SIT-bachgroundColor-div {
    width: 89%;
    border-bottom: 1px solid black;
    display: flex;
    margin-bottom: 20px;
}

.employees-background-color {
    width: 90%;
    border-bottom: 1px solid black;
    display: flex;
    margin-bottom: 20px;
}

.employees-h6 {
    margin: 0px 0px 0px -5px;
}

.rder_list_header_countainer {
    height: 100%;
    width: 100%;
}

.order-list-table {
    width: 95.3%;
    margin: auto;
    background-color: yellow;
}

.order-list-second-tr {
    height: 55px;
}

.order-list-orderId-status {
    margin-top: 10px;
    margin-left: 20px;
}

.order-list-constatnt-header-title {
    width: 95.3%;
    display: flex;
    justify-content: space-between;
    background-color: red;
}

.order-list-container-div {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 12px;
    height: 80px;
    /* background-color: #fff9fc; */
    border: .1px solid rgb(235, 229, 229);
    /* background-color: rgb(176, 255, 242); */
    border-bottom: 1px solid rgb(212, 207, 207);
}

.order-list-container-div-1 {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border: .1px solid rgb(235, 229, 229);
    border-bottom: 1px solid rgb(212, 207, 207);
    padding: 0px 10px;
    /* background-color: rgb(251, 15, 15); */
    align-items: center
}

.order-list-container:hover {
    border: .9px solid var(--main_color);
    cursor: pointer;
}

.header-list-container-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-top: 45px;
    padding-top: 13px;
    padding: 16px 7px 0px 3px;
    border: 1px solid rgb(230, 225, 225);
    border-top: 1px solid rgb(252, 247, 247);
    height: 60px;
    border-radius: 4px;
}

.header-list-container-div-1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-top: 45px;
    border: 1px solid rgb(230, 225, 225);
    border-top: 1px solid rgb(252, 247, 247);
    height: 60px;
    border-radius: 4px;
    align-items: center;
    padding: 0px 10px;
}

.order-list-div-icon {
    margin: 8px 0px 0px -6px;
    width: 45px;
    height: 45px;
    background-color: #fa92d4;
    padding: 10px 10px 12px 16px;
    border-radius: 100px;
}

.order-list-div-icon-1 {
    width: 45px;
    height: 45px;
    background-color: #fa92d4;
    border-radius: 100px;
    background-color: rgb(246, 241, 194);
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-list-container-div-third-div {
    display: flex;
    width: 85%;
    padding: 14px 10px 10px 8px;
    justify-content: space-between;
}

.bottom-list-p {
    text-align: center;
    width: 26%;
}

.bottom-list-p-1 {
    width: 16.6%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items:center;
 
}

.order-list-item-container-icon-status {
    width: 17.9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.header-list-p {
    text-align: center;
    width: 30%;
}

.header-list-p-1 {
    text-align: center;
    width: 16.6%;
    margin: 0;
    display: flex;
   
    justify-content: center;
}
/* .header-list-p-11{
     text-align: center;
    width: 16.6%;
    margin: 0;
    display: flex;
    justify-content: center; 
} */

.devics-item-list-p {
    text-align: center;
    width: 32%;

}

.devics-item-list-button {
    text-align: center;
    width: 32%;
    height: 40px;
}

.order-list-container-div button {
    width: var(--button_width);
    height: 100%;
    margin: 0 15px 0 0;
    border-radius: var(--button_border_radius);
    background-color: white;
    border: 1px solid var(--main_navbar_button_text_color);
}

.order-list-container-div button:disabled:hover {
    border: 1px solid var(--main_navbar_button_text_color);
}

.order-list-container-div button:hover,
.order-list-container-div button:focus {
    border: 1px solid var(--main_color);
}

.main_container {
    height: 100vh;
    width: 100%;
}


/* SUBMENU CSS */

.i_trash {
    font-size: 35px;
    color: rgb(227, 49, 49);
    margin: 15px 0px 0px 5px;
    cursor: pointer;
}

.no-option-sm {
    left: 500px;
    position: absolute;
    top: 330px;
}

.countainig-inputs-div-sm {
    width: 33.5%;
    height: 130px;
    border: 1px solid rgb(226, 220, 220);
    box-shadow: 1px 1px 5px rgb(230, 224, 224), -4px -1px 6px rgb(255, 251, 251);
    position: fixed;
    bottom: 0px;
    margin-left: 317px;
    margin-bottom: 27px;
    display: flex;
    border-radius: 10px;
    z-index: 2;
    background-color: white;
}

.add_produc-button {
    width: 140px;
    height: 58px;
    border-radius: 10px;
    margin: 38px -40px 10px 20px;
    background-color: white;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgb(223, 218, 218), -3px -1px 8px rgb(235, 229, 229);
}

.first-input {
    width: 270px;
    height: 33px;
    margin: 15px 5px 5px 25px;
    border: none;
    outline: none;
    padding: 13px 0px 14px 10px;
    font-size: 20px;
}

.sm-inputs {
    margin-top: 12px;
}

.secound-input {
    border: none;
    height: 25px;
    width: 270px;
    margin: 8px 5px 5px 25px;
    outline: none;
    padding: 12px 0px 14px 10px;
    font-size: 17px;
}

.secound-input:focus,
.first-input:focus,
.first-input:hover,
.secound-input:hover {
    border: 2px solid black;
}

.countaining {
    width: 97%;
    margin: 15px 9px 18px 13px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(246, 238, 238);
    border-radius: 3px;
    /* box-shadow: 3px 3px 4px rgb(234, 229, 229), 0px -3px 5px rgb(234, 229, 229); */
}


/* .CO-div3{
    margin-bottom: -20%;
} */

.countaining-subset-sm {
    width: 27%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.optionList-countaining-subset-div {
    width: 24%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.subset-div-sm {
    cursor: pointer;
    width: 40%;
    height: 40px;
    border: 1px solid rgb(195, 190, 190);
    text-align: center;
    padding-top: 7px;
    margin: 12px 4px 12px 7px;
    border-radius: 10px;
    /* box-shadow: 5px 5px 4px rgb(237, 232, 232), -1px -1px 4px rgb(238, 233, 233); */
}

.optionList-subset-div {
    cursor: pointer;
    width: 60%;
    height: 42px;
    border: 1px solid rgb(195, 190, 190);
    text-align: center;
    padding-top: 7px;
    margin: 12px -4px 12px 38px;
    border-radius: 10px;
    box-shadow: 5px 5px 4px rgb(237, 232, 232), -1px -1px 4px rgb(238, 233, 233);
}

.subset-div-sm:hover,
.optionList-subset-div:hover {
    background-color: blue;
    color: white;
}

.product-name-p {
    margin: 11px 5px 12px 22px;
    font-size: 25px;
}

.content-h6 {
    text-align: left;
    margin-bottom: -2px;
    margin-top: 2px;
}

.fa-arrow-right {
    transform: rotate(180deg);
    color: rgb(207, 199, 199);
    margin-left: 1px;
    font-size: 20px;
    cursor: pointer;
}


/* .div-1-product {
    margin-top: 50px;
 
} */


/*SB/EDIT */

.sm-container {
    height: 100%;
    width: 100%;
    box-shadow: 1px 1px 8px rgb(234, 228, 228), -4px 0 6px rgb(243, 237, 237);
    padding-top: 10px;
}

.sm-div2,
.sm-div1,
.sm-div3,
.sm-div4 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}

.sm-div11 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.driver-sm-div1 {
    width: 100%;
    /* background-color: rgb(246, 242, 242); */
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}
.drivers-container-image-closeIcon{
position: relative;
/* border: 1px solid red; */
}
.drivers-container-image-closeIcon i{
    position: absolute;
    font-size: 37px;
    left: 42%;
    top: 42%;
    cursor: pointer;
    z-index: 300000000;
color: white;
}

.device-sm-div2 {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}

.SIE-div2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}

.SIE-div2-chechbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}

.sm-input {
    width: 100%;
    height: var(--button_height);
    border: 0.3px solid var(--main_navbar_button_text_color);
    outline: none;
    /* box-shadow: 1px 1px 1px rgb(231, 228, 228); */
}

.sm-input-driver {
    width: 100%;
    height: var(--button_height);
    border: 0.3px solid var(--main_navbar_button_text_color);
    outline: none;
}

.SE-secoundLanguage {
    margin-left: 9px;
}


/* .sm-maxselect-sort {
    margin-top: .5%;
    margin-left:1.7%;
    
} */

.sm-m {
 
    width: 28%;
    /* border: 1px solid pink; */
}

.sm-div3-3 {
    margin-right: 218px;
}

.sm-div3-3-h6 {
    margin: 50px -16px -23px 20px;
}

.sm-div4-h6 {
    margin: 22px 0px -22px 20px;
}

.sm-div1-2 {
    width: 100%;
    border: 1px solid rgb(221, 217, 217);
    box-shadow: 1px 1px 1px rgb(231, 228, 228);
    height: 135px;
    /* padding: 10px 1px 6px 10px; */
}

.sm-div1-input1 {
    height: 30%;
    width: 90%;
    font-size: 18px;
    margin: 7% 4% 2% 5%;
    border: none;
    outline: none;
    padding: 18px 0px 21px var( --normal_padding_left);
    border: 1px solid rgb(245, 239, 239);
    box-shadow: 1px 1px 5px rgb(238, 231, 231);
}

.sm-div1-input2 {
    height: 25%;
    width: 90%;
    margin: 5% 4% 0% 5%;
    border: none;
    outline: none;
    padding: 14px 0px 16px var( --normal_padding_left);
    border: 1px solid rgb(238, 231, 231);
    box-shadow: 1px 1px 5px rgb(228, 225, 225);
}

.sm-div1-input2:hover,
.sm-div1-input2:focus,
.sm-div1-input1:hover,
.sm-div1-input1:focus,
.sm-input:focus,
.sm-input:hover,
.employees-image-input:hover,
.employees-image-input:focus {
    border: 1px solid var(--main_color)
}

.sm-h6 {
    margin-top: -6px;
}

.fa-arrow-right {
    margin-bottom: 12px;
    transform: rotate(180deg);
    color: rgb(207, 199, 199);
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
}

.sm_button {
    width: 79.5%;
    background-color: rgb(255, 0, 191);
    height: 45px;
    text-align: center;
    color: white;
    font-size: 20px;
    position: fixed;
    margin-top: -25px;
    bottom: 10px;
    margin-left: -1px;
    background-color: red;
}


/* MENU CSS */

.div-2,
.div-1,
.div-3,
.div-4,
.div-5,
.div-6,
.div-7,
.PSE-div7,
.PSE-div8,
.PSE-div9 {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}


/* .div-1 {
    margin: 30px 0px 0px 0px;

} */

.div-3-crust {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}

.text {
    width: 32.5%;
   
}

.h6 {
    color: rgb(91, 88, 88);
}

.div-1-1 {
    width: 100%;
    border: 1px solid rgb(221, 217, 217);
    box-shadow: 1px 1px 1px rgb(231, 228, 228);
    height: 112px;
    height: 130px;
    margin-bottom: 0px;
}

.input-2:hover,
.input-1:hover,
.input-1:focus,
.sort-input:focus,
.sort-input:hover,
.input-2:focus,
.delevery-area-popup-container-edit-box:hover,.delevery-area-popup-container-edit-box:focus
 {
    /* border: 2px solid black; */
    border: 1px solid var(--main_color);
}

.input-1 {
    height: 29px;
    width: 90%;
    font-size: 17px;
    margin: 8% 5% 4% 4.5%;
    border: none;
    outline: none;
    padding: 18px 0px 21px var( --normal_padding_left);
    border: 0.3px solid var(--main_navbar_button_text_color);
    box-shadow: 1px 1px 5px rgb(238, 231, 231);
}

.input-21 {
    height: 25px;
    width: 90%;
    margin-bottom: 13px;
    margin: 3% 5% 0% 4.5%;
    border: none;
    outline: none;
    padding: 14px 0px 16px var( --normal_padding_left);
    border: 0.3px solid var(--main_navbar_button_text_color);
    box-shadow: 1px 1px 5px rgb(228, 225, 225);
}

.input-2 {
    height: 25px;
    width: 90%;
    margin-bottom: 13px;
    margin: 3% 5% 0% 4.5%;
    border: none;
    outline: none;
    padding: 14px 0px 16px var( --normal_padding_left);
    border: 0.3px solid var(--main_navbar_button_text_color);
    box-shadow: 1px 1px 5px rgb(228, 225, 225);
}

.input-1::placeholder {
    font-size: 20px;
}

.title {
    text-align: left;
}

.show-hide {
    text-align: left;
}

.sort-input ,.category-sort-input{
    width: 100%;
    height: var(--button_height);
    width: 100%;
    outline: none;
    border: 0.3px solid var(--main_navbar_button_text_color);
    border-radius: 2px;
}

.sort-input1 {
    width: 100%;
    height: var(--button_height);
    width: 100%;
    outline: none;
    border: 0.3px solid var(--main_navbar_button_text_color);
    border-radius: 2px;
}

.profile-disbled-input {
    width: 100%;
    height: var(--button_height);
    width: 100%;
    outline: none;
    border: 0.3px solid var(--main_navbar_button_text_color);
    border-radius: 2px;
}

.sort-input::placeholder,
.profile-disbled-input::placeholder {
    font-size: 17px;
}

.sort-title {
    text-align: left;
}


/* .div-4-1 {
    margin-top: -60px;
} */

.file-i {
    padding: 1px 1px 0px 0px;
    border: 1px solid rgb(235, 230, 230);
    box-shadow: 1px 1px 1px rgb(227, 221, 221);
    width: 100%;
    /* margin-left: -17px; */
    margin-bottom: 10px;
    cursor: pointer;
}
.file-i1 {
    padding: 1px 1px 0px 0px;
    border: 1px solid rgb(235, 230, 230);
    box-shadow: 1px 1px 1px rgb(227, 221, 221);
    width: 100%;
    /* margin-left: -17px; */
    margin-bottom: 10px;
}

.SIE-file-i {
    padding: 1px 1px 0px 0px;
    border: 1px solid rgb(235, 230, 230);
    box-shadow: 1px 1px 1px rgb(227, 221, 221);
    width: 87%;
    /* margin-left: -17px; */
    margin-bottom: 10px;
}

.web-setting-input-type-file {
    padding: 1px 1px 0px 0px;
    border: 1px solid rgb(235, 230, 230);
    box-shadow: 1px 1px 1px rgb(227, 221, 221);
    width: 100%;
    /* margin-left: -17px; */
    margin-bottom: 10px;
}

.image-item {
    width: 100%;
    height: 110px;
    border: 1px solid rgb(216, 213, 213);
    box-shadow: 1px 1px 1px rgb(232, 227, 227);
}

.SIE-image-item {
    width: 87%;
    height: 110px;
    border: 1px solid rgb(216, 213, 213);
    box-shadow: 1px 1px 1px rgb(232, 227, 227);
}

.web-setting-container-image-div {
    width: 100%;
    height: 110px;
    border: 1px solid rgb(216, 213, 213);
    box-shadow: 1px 1px 1px rgb(232, 227, 227);
}

.p-image {
    text-align: left;
    /* margin: -15px 0px 10px -12px; */
}

.div-chechbox-p {
    display: flex;
    /* align-items: center; */
    height: 20px;
}

.p-checkbox {
    text-align: left;
    margin: 3px 0px 0px -16px;
}

.div-9 {
    width: 32.5%;
    margin: var(--normal_margin_top) 0px 50px 0px;
    /* height: 30px; */
    /* padding-bottom: 120px; */
}

.countainer-checkbox {
    margin-top: 48px;
}

.countainer-checkbox1 {
    margin-top: 36px;
}

.countainer-checkbox12 {
    margin-top: 15px;
}

.users-countainer-checkbox {
    margin-top: 40px;
}

.backgroundColor-input {
    height: 98%;
    width: 92%;
    border: none;
    outline: none;
    padding: 0px 0px 0px 6px;
}

.bachgroundColor {
    width: 8%;
    height: 20px;
    border-radius: 50px;
    height: 20px;
}

.SketchPicker-div {
    position: absolute;
    top: 60px;
    left: 8px;
    z-index: 3;
}
.fast-edit-SketchPicker-div{
      position: absolute;
    top: 50px;
    /* left: 8px; */
    right: 20px;
    z-index: 3;
}
.h6-text {
    text-align: left;
}

.countaining-colorPicker {
    position: relative;
    margin-bottom: 50px;
}
.category-container-color-box{
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* border: .2px solid rgb(199, 199, 199); */
    padding: 6px;
     border-radius: 3px;
}
.category-container-color-box div{
    width:20%;
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    border: .2px solid grey;
    cursor: pointer;
}
.Appearance_information {
    margin-bottom: 50px;
}

.SIE-Appearance_information {
    width: 91%;
    margin: 50px 0px 50px 0px;
}

.Appearance_information_input {
    height: var( --button_height);
    width: 100%;
    border: 0.3px solid var(--main_navbar_button_text_color);
    outline: none;
}

.Appearance_information_input:hover,
.Appearance_information_input:focus,
.SIE-file-i:hover,
.SIE-file-i:focus,
.color-first-input:hover,
.color-first-input:focus {
    border: 1px solid var(--main_color);
}


/* .checkbox {
    margin: 12px 2px 20px 18px;
} */

.div-6-1 {
    margin-top: -25px;
}

.autocomplete-title {
    text-align: left;
    /* margin: 0px 0px 0px -15px; */
}

.input-w {
    width: 350px;
    height: var(--button_height);
    /* margin: 24px 0px 0px 0px; */
    border: .3px solid var(--main_navbar_button_text_color);
    outline: none;
}

.CE-secound-language-input {
    outline: none;
    width: 100%;
    height: var(--button_height);
    /* margin: 24px 0px 0px 0px; */
    border: .3px solid var(--main_navbar_button_text_color);
    /* outline: none;background-color: red; */
}

.input-w:hover,
.input-w:focus,
.CE-secound-language-input:hover,
.CE-secound-language-input:focus,
.file-i:hover,
.file-i:focus {
    border: 1px solid var(--main_color);
}

.multiselect_printer {
    margin-right: -50px;
    width: 350px;
    margin: -45px 25px 15px 1px;
}

.printer {
    text-align: left;
    /* margin:-10px 0px 5px 2px; */
}


/* .painter-selector{
    width: 100%;
} */

.Secound-language {
    text-align: left;
    /* margin: -10px 0px -18px 2px; */
}

.div-8 {
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin: 25px 0px 20px 25px; */
    margin: 3% 0% 5% 0%;
}

.div-8-1 {
    display: flex;
    text-align: center;
    width: 40%;
    height: 20px;
}

.div-8-2 {
    display: flex;
    width: 40%;
    margin-bottom: 40px;
    text-align: center;
    height: 20px;
}

.checkbox-2-p {
    margin: 8px 20px 10px -4px;
}

.end-button {
    width: 56.2%;
    background-color: rgb(255, 0, 191);
    height: 45px;
    text-align: center;
    color: white;
    font-size: 20px;
    position: fixed;
    bottom: 10px;
    margin-left: -1px;
}

.category_title {
    margin: 0;
    color: black;
    font-weight: bold;
}

.general-category-title {
    margin-top: 6px;
}

.category_item_container {
    margin: 30px 25px 0px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.caret-down-icon {
    /* position: absolute;
    top: 10px;
    left: 90%; */
}

.addbox {
    width: 222px;
    border: 1px solid rgb(203, 200, 200);
    box-shadow: 1px 1px 1px rgb(194, 191, 191);
    display: flex;
    bottom: 10px;
    padding: 8px;
    left: 266px;
    position: fixed;
    background-color: white;
    margin-top: 200px;
}

.add-p {
    position: absolute;
    bottom: 300px;
    left: 90px;
}


/* Product styles */

.div-10,
.div-11,
.div-12,
.div-13,
.div-14,
.div-15 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 57px 0px 57px 0px;
}

.div-10-input {
    width: 100%;
    height: var(--button_height);
    border: .3px solid var(--main_navbar_button_text_color);
    outline: none;
}

.div-10-input:hover,
.div-10-input:focus {
    border: 1px solid var(--main_color);
}

.div-12-products,
.div-13-product {
    width: 100%;
}

.div-12-products {
    margin-top: -15px;
}

.div-10-1 {
    width: 24%;
}

.div-11 {
    margin-bottom: -5px;
}

.div-11-checkboxDiv {
    display: flex;
    align-items: center;
    height: 20px;
}

.div-11-checkbox-h6 {
    margin-top: 7px;
    margin-left: -5px;
}

.div-11-firstDiv {
    margin-top: -22px;
}

.div-11-input-checkbox {
    margin-top: 11px;
}

.div-12-1 {
    margin-top: -5px;
}

.div-14-endDiv {
    margin: 45px 0px 0px -20px;
}

.div-14-inputText,
.div-11-inputText {
    height: var(--button_height);
    border: .3px solid var(--main_navbar_button_text_color);
    outline: none;
    width: 100%;
}

.div-14-inputText {
    width: 100%;
}

.div-14-inputText:hover,
.div-11-inputText:hover,
.div-14-inputText:focus,
.div-11-inputText:focus {
    border: 1px solid var(--main_color);
}

.div-15 {
    margin-bottom: 8%;
    margin-top: 7%;
}

.div-14-inputCheckbox-h6,
.div-15-h6 {
    margin: 0px 18px -20px 20px;
}

.multiselect_product_linkto,
.multiselect_product_printer {
    width: 350px;
    margin-bottom: 25px;
}

.multiselect_product_linkto {
    margin-right: 13px;
}


/* categoryOption style */

.div-6-categoryOption {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 50px 0px 50px 0px;
}

.div-6-categoryOption-input {
    height: var(--button_height);
    width: 100%;
    outline: none;
    border: .3px solid var(--main_navbar_button_text_color);
}

.category-option-dropdown-div,
.category-option-div-multiselect {
    width: 37%;
}

.div-6-categoryOption-input:hover,
.div-6-categoryOption-input:focus,
.div-7-categoryOption-input:hover,
.div-7-categoryOption-input:focus {
    border: 1px solid var(--main_color);
}

.categoeyOption-div6 {
    display: flex;
    margin: 6px 5px 0px 15px;
    justify-content: center;
}

.categoryOption-text {
    text-align: left;
    /* margin: 0px 0px 5px -15px; */
}

.categoryOption-content {
    text-align: left;
    /* margin: 3px 0px 1px 10px; */
}

.poe-div4-h6 {
    text-align: left;
}

.categoryOPtion-checkbox-h6 {
    margin: -3px 0px 0px -6px;
}

.categoryOPtion-multiselect-vat {
    margin-left: 25px;
    width: 105%;
}

.div-7-categoryOption {
    width: 100%;
    margin: 50px 0px 50px 0px;
}

.div-7-1-categoryOption {
    display: flex;
    width: 100%;
    margin: 50px 0px 50px 0px;
}

.div-7-2-categoryOption {
    margin-left: 17px;
    width: 87%;
    display: flex;
    margin-top: -6px;
    justify-content: space-between;
}

.div-7-categoryOption-input {
    height: var(--button_height);
    outline: none;
    border: .3px solid var(--main_navbar_button_text_color);
}

.div-7-categoryOption-div-h6 {
    width: 86.5%;
    margin-left: 99px;
    display: flex;
    justify-content: space-between;
    margin-bottom: -15px;
}

.div-7-categoryOption-eatin {
    margin-right: 143px;
}

.div-7-categoryOption-Takeaway {
    margin-right: -115px;
}


/* optionList */

.i_trash {
    font-size: 35px;
    color: rgb(227, 49, 49);
    margin: 15px 0px 0px 5px;
    cursor: pointer;
}

.no-option {
    left: 370px;
    position: absolute;
    top: 310px;
}

.countainig-inputs-div {
    width: 33.5%;
    height: 110px;
    border: 1px solid rgb(226, 220, 220);
    box-shadow: 1px 1px 5px rgb(230, 224, 224), -4px -1px 6px rgb(255, 251, 251);
    position: fixed;
    bottom: 0px;
    margin-left: 160px;
    margin-bottom: 20px;
    display: flex;
    border-radius: 10px;
    z-index: 2;
    background-color: white;
}

.add_produc-button {
    width: 140px;
    height: 58px;
    border-radius: 10px;
    margin: 27px -40px 10px 20px;
    background-color: white;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgb(223, 218, 218), -3px -1px 8px rgb(235, 229, 229);
}

.first-input {
    width: 270px;
    height: 33px;
    margin: 15px 5px 5px 25px;
    border: none;
    outline: none;
    padding: 5px 0px 9px 10px;
    font-size: 20px;
}

.secound-input {
    border: none;
    height: 25px;
    width: 270px;
    margin: 8px 5px 5px 25px;
    outline: none;
    padding: 5px 0px 8px 10px;
    font-size: 17px;
}

.secound-input:focus,
.first-input:focus,
.first-input:hover,
.secound-input:hover {
    border: 2px solid black;
}

.countaining {
    width: 97%;
    margin: 15px 9px 18px 13px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(246, 238, 238);
    border-radius: 3px;
    /* box-shadow: 3px 3px 4px rgb(234, 229, 229), 0px -3px 5px rgb(234, 229, 229); */
}

.countaining-subset-div {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.optionList-countaining-subset-div {
    width: 24%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.subset-div {
    cursor: pointer;
    width: 30%;
    height: 40px;
    border: 1px solid rgb(195, 190, 190);
    text-align: center;
    padding-top: 7px;
    margin: 12px 4px 12px 7px;
    border-radius: 10px;
    background-color: white;
    color: black;
    box-shadow: 5px 5px 4px rgb(237, 232, 232), -1px -1px 4px rgb(238, 233, 233)
}

.subset-div_product_submenu_popup {
    cursor: pointer;
    width: 30%;
    height: 40px;
    border: 1px solid rgb(195, 190, 190);
    text-align: center;
    padding-top: 7px;
    margin: 12px 4px 12px 7px;
    border-radius: 10px;
    background-color: white;
    color: black;
}

.optionList-subset-div {
    cursor: pointer;
    width: 60%;
    height: 42px;
    border: 1px solid rgb(195, 190, 190);
    text-align: center;
    padding-top: 7px;
    margin: 12px -4px 12px 38px;
    border-radius: 10px;
    box-shadow: 5px 5px 4px rgb(237, 232, 232), -1px -1px 4px rgb(238, 233, 233)
}

.subset-div:hover,
.optionList-subset-div:hover {
    background-color: blue;
    color: white;
}


/* .subset-div-option{
    width:16%;
    margin-right:21px;
  } */

.product-name-p {
    margin: 11px 5px 12px 22px;
    font-size: 25px;
}

.content-h6 {
    text-align: left;
    margin-bottom: -2px;
    margin-top: 2px;
}

.fa-arrow-right {
    transform: rotate(180deg);
    color: rgb(207, 199, 199);
    margin-left: 1px;
    font-size: 20px;
    cursor: pointer;
}

.div-1-product {
    margin-top: 30px;
}

.poe-div4,
.poe-div-multiselect,
.div6-poe {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 50px 0px 50px 0px;
}


/* .poe-div-multiselect{
    margin: -40px 0px 30px 0px;
} */

.poe-div4-div {
    width: 24%;
}

.div1-poe {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-left: 36px;
}

.poe-input {
    width: 100%;
    height: var(--button_height);
    outline: none;
    border: .3px solid var(--main_navbar_button_text_color);
}

.poe-input:hover,
.poe-input:focus {
    border: 1px solid var(--main_color);
}

.poe-input1 {
    height: var(--button_height);
    width: 100%;
    font-size: 18px;
    /* margin: 12px 10px 5px -9px; */
    border: none;
    outline: none;
    padding: 18px 0px 21px 10px;
    border: 1px solid rgb(245, 239, 239);
    box-shadow: 1px 1px 5px rgb(238, 231, 231);
}

.poe-input11 {
    height: var(--button_height);
    width: 100%;
    font-size: 18px;
    /* margin: 12px 10px 5px -9px; */
    border: none;
    outline: none;
    padding: 18px 0px 21px 10px;
    border: 1px solid rgb(245, 239, 239);
    box-shadow: 1px 1px 5px rgb(238, 231, 231);
}

.poe-input1:hover,
.poe-input1:focus {
    border: 1px solid var(--main_color);
}

.poe-title-div {
    width: 33%;
}

.poe-text {
    width: 25%;
}


/* .div6-poe {
    width: 100%;
    display: flex;
    justify-content: space-between;
     margin: 70px 0px 30px 3px;
} */

.poe-h6 {
    text-align: left;
    /* margin: 0px 50px -2px -6px; */
}

.add_new_shop_container {
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: flex-end;
}

.add_new_shop_container span {
    background-color: #388e3c;
    display: flex;
    align-items: center;
    color: white;
    padding: 0 10px;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.add_new_shop_container p {
    margin: 0 10px 0 0;
}

.create_shop_popup_container {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    z-index: 12345667;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-licence-popup-container {
    position: absolute;
    z-index: 12345667;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* opacity: .3; */
    /* background-color: #878686; */
}

.create_shop_popup_content_container {
    background-color: white;
    height: 90%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    position: relative;
}

.driver_popup_content_container {
    background-color: white;
    /* height: 90%;
    width: 50%; */
    /* width: 33%; height: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    /* padding-bottom: 2%; */
    position: relative;
    width: 42%;
    height: 66%
}

.post-code-content-container {
    height: 50%;
    width: 33%;
    padding: 1.5% 0% 1% 0%;
}
.email_notification_container_div{
     height: 40%;
    width: 30%;
}
.create-licence-popup-content-container {
    background-color: #f9f6f6;
    height: 90%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    position: relative;
    border: 1px solid #0f0e0e;
}


/* APP SETTINGS */

.AGS-div1 {
    width: 100%;
    height: 100%;
    border: 1px solid red;
}

.ASOP-div1,
.ASOP-div2,
.ASOP-div3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom);
}

.AGS-div111{
       width: 100%;
    /* display: flex; */
    justify-content: space-between;
    margin-top: var( --normal_margin_top);
    margin-bottom: var( --normal_margin_bottom); 
}

/* .ASOP-div3 {
    width: 95%;
} */


/* .ASOP-div3-1,
.ASOP-div2-1 {
    display: flex;
} */

.ASOP-h6 {
    margin: 0;
    font-size: 16px;
    color: rgb(91, 88, 88);
    /* width: 30%;
    background-color: red;
    text-align: center; */
}

.div-checkbox-h6 {
    margin-top: -30px;
    display: flex;
    align-items: center;
}

.div-checkbox {
    margin-top: 47px;
}

.ASOP-input-text {
    width: 100%;
    height: var(--button_height);
    outline: none;
    border: .3px solid var(--main_navbar_button_text_color);
}

.ASOP-input-text:hover,
.ASOP-input-text:focus,
.meta-textarea:hover,.meta-textarea:focus {
    border: 1px solid var(--main_color);
}
.meta-textarea{
     width: 100%;
    min-height: var(--button_height);
    outline: none;
    border: .3px solid var(--main_navbar_button_text_color);
}
.ASOP-div1-input-text {
    width: 30%;
}


/* ORDER CSS */

.header-div {
    display: flex;
    margin-bottom: 18px;
}

.header-div p {
    margin-right: 20px;
    font-weight: 600;
    margin-left: 12px;
}

.header-div button {
    height: 30px;
    background-color: white;
    color: rgb(14, 14, 154);
    padding: 3px 12px;
    border: 1px solid rgb(188, 181, 181);
    border-radius: 3px;
    font-size: small;
}

.learn-more {
    float: right;
    margin-top: -60px;
    margin-right: 135px;
}

.middlediv {
    display: inline-flex;
}

.box {
    width: 270px;
    border: 1px solid black;
    margin: 0px 10px;
    margin-top: 15px;
    border-radius: 3px;
}

.search-box {
    min-height: 45px;
    position: relative;
}

.i-search {
    position: absolute;
    display: flex;
    top: 15px;
    right: 15px;
    color: grey;
}

.times-i {
    position: absolute;
    display: flex;
    top: 15px;
    right: 42px;
    font-size: 15px;
    color: grey;
}

.deleted {
    display: inline-block;
    width: 200PX;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 8px;
}

.deleted2 {
    width: 230PX;
}

.i-i {
    display: inline-block;
}

.ul-div {
    width: 270px;
    max-height: 185px;
    margin-left: 10px;
    margin-top: 10px;
    border: 1px solid rgb(193, 189, 189);
    border-radius: 3px;
    background-color: white;
    box-shadow: 1px 1px 1px rgb(177, 175, 175);
}

.list,
.inner-channel-div,
.div10 {
    padding-left: 15px;
    height: 40px;
    margin-top: 2px;
    padding-top: 6px;
    margin-bottom: 2px;
}

.list:hover,
.inner-channel-div:hover,
.div10:hover {
    background-color: rgb(220, 215, 215);
}

.deletedStores {
    width: 140px;
    margin-top: -18px;
}

.delete-div {
    min-width: 60px;
    height: 27px;
    font-size: 13px;
    border-radius: 50px;
    padding: 2px 8px;
    background-color: rgb(209, 211, 212);
    display: inline-block;
    margin-left: 10px;
    margin-top: 7px;
    position: relative;
}

.timesicon {
    font-size: 19px;
    color: rgb(86, 84, 84);
    margin-left: 6px;
}

.chanels-box {
    position: relative;
    min-height: 45px;
}

.dropdonicon {
    position: absolute;
    right: 10px;
    top: 16px;
    font-size: 11px;
}

.icontimes {
    position: absolute;
    right: -90px;
    top: 16px;
    font-weight: 100;
    font-size: 15px;
    color: grey;
}

.channel-div {
    width: 270px;
    margin-left: 10px;
    border: 1px solid rgb(193, 189, 189);
    margin-top: 10px;
    border-radius: 3px;
    background-color: white;
    box-shadow: 1px 1px 1px rgb(177, 175, 175);
    overflow-y: scroll;
    max-height: 260px;
}

.chanels-box-pp {
    position: absolute;
    top: 10px;
    font-size: 14px;
    left: 10px;
}

.stores-box {
    min-height: 45px;
    position: relative;
}

.stores-box-p {
    margin-top: -12px;
    margin-left: 8px;
    font-size: 12px;
    background-color: rgb(255, 255, 255);
    width: 41px;
    padding-left: 4px;
}

.chanels-box-p {
    margin-top: -10px;
    width: 55px;
    background-color: white;
    margin-left: 5px;
    padding-left: 5px;
    font-weight: 350;
    font-size: 12px;
}

.shopping-bag-icon {
    color: white;
    background-color: rgb(105, 222, 187);
    padding: 9px;
    border-radius: 200px;
    margin: 14px -10px;
    margin-left: 20px;
    margin-right: 11px;
    display: inline-flex;
    font-size: 18px;
}

.no-options {
    margin-left: 15px;
    margin-top: 10px;
    font-size: 16px;
}

.autocomplete {
    width: 270px;
    margin: 0px 10px;
}


/* .PSE-div3-input {
    width: 32.5%;
} */

.PSE-input {
    width: 100%;
    height: var(--button_height);
    border: .3px solid var(--main_navbar_button_text_color);
    outline: none;
}

.PSE-input:hover,
.PSE-input:focus {
    border: 1px solid var(--main_color);
}

.PSE-div9 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: var(--normal_margin_top) 0px var(--normal_margin_bottom) 0px;
}

.COCE-div9 {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
}

.input-padding {
    padding-left: var( --normal_padding_left);
}

.sidebar_up_down_container {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 15px;
    height: 20px;
    margin-right: 5px;
}

.sidebar_up_down_container i {
    font-size: 10px;
}

.sidebar_title_icon_container {
    display: flex;
    align-items: center;
    width: 150px;
}

.sidebar_title_icon_container i {
    margin-right: 30px;
    font-size: 20px;
}

.sidebar_content_color {
    color: #525252;
}

.sidebar_ul {
    list-style-type: none;
    margin: 45px 0 0 0;
}

.sidebar_li_container {
    /* margin-bottom: 5px;
    background-color: blueviolet; */
}

.PSE-div3 {
    display: flex;
    justify-content: space-between;
    margin: 0px 25px 35px 29px;
}

.optionList-countaining-subset-div-si {
    width: 19%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.optionList-subset-div-si {
    cursor: pointer;
    width: 60%;
    height: 42px;
    border: 1px solid rgb(195, 190, 190);
    text-align: center;
    padding-top: 7px;
    margin: 12px -6px 12px 45px;
    border-radius: 10px;
    box-shadow: 5px 5px 4px rgb(237, 232, 232), -1px -1px 4px rgb(238, 233, 233)
}

#main_brand {
    display: flex;
    align-items: center;
}

.create-shop-close-div {
    position: absolute;
    top: 0;
    right: 0;
    width: 7%;
    height: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shop-group-popup-input {
    margin: auto;
    margin: 30px 0px 10px 0px;
}

.create-shop-close-div-post-code {
    position: relative;
    /*top:0;
    right:0; */
    width: 90%;
    height: 12%;
    /* display:flex; */
    padding: 0px 10px 9px 10px;
    /* justify-content:space-between; */
    align-items: center;
    border-bottom: 0.3px solid var(--main_navbar_button_text_color);
}

.container-input-div {
    height: 70%;
    width: 100%;
    margin-bottom: 15px
}

.post-code-close-icon {
    position: absolute;
    right: -12px;
    top: 14px;
    font-size: 25;
    cursor: pointer;
    margin-top: -30px
}

.shop-group-close-icon {
    position: absolute;
    right: 10px;
    top: 35px;
    cursor: pointer;
    margin-top: -30px
}

.post-code-p {
    text-align: center;
    font-weight: 600;
    margin-top: 6px;
}

.post-code-h6 {
    margin-bottom: 0px;
}

.header-list-requested-for-p {
    margin-left: 3px;
    margin-right: -5px;
}

.order-list-item-channels-p {
    margin-left: -7px;
    margin-right: 4px;
}

.side-bar-first-div {
    width: 95%;
    margin-bottom: 10px;

}
.side-bar-first-div-1{
      width: 95%;
    margin-bottom: 10px;
     border: 1px solid var( --main_navbar_button_text_color) ;
                  height: 40px;
                  margin-left: 11px;
                  display: flex;
                  justify-content: center;
                  align-items: center
}

/* //USER-SIDE-BAR STYLES */

.user-sideBar-container-div {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100%;
    /* 
    padding-top:50px; */
}

.user-sidebar-title {
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 220px;
    height: 40px;
    margin-bottom: 10px;
    padding-left: 20px;
}

.device-list-p {
    margin-top: 15px;
}

.licence-popup-drop-down {
    width: 80%;
    margin: 10px auto;
}

.licence-popup-p {
    text-align: left;
}


/* body{
    background-color: rgb(252, 247, 252);
} */

.shop-logo {
    width: 40px;
    height: 40px;
    margin-top: 4px;
    margin-right: -3px;
    margin-left: -6px;
    border-radius: 200px;
}

.shop-logo-devices {
    width: 40px;
    height: 40px;
    margin-top: -3px;
    margin-right: 6px;
    margin-left: -6px;
    border-radius: 200px;
}

.half-and_half-radio-buutons {
    background-color: #388e3c;
}


/* Completely Hiding Radio Button */

Input[type="radio"] {
    Display: None;
}


/* Simulate Radiobutton Appearance Using Pseudoselector */

Input[type="radio"]+Label:before {
    Content: "";
    /* Create Custom Radiobutton Appearance */
    Display: Inline-block;
    Width: 18px;
    Height: 18px;
    Padding: 3px;
    Margin-right: 5px;
    /* Background-color Only For Content */
    Background-clip: Content-box;
    Border: 1px Solid black;
    Background-color: white;
    Border-radius: 50%;
}


/* Appearance Of Checked Radiobutton */

Input[type="radio"]:checked+Label:before {
    Background-color: var(--main_color);
    Border: 1px Solid var(--main_color);
}


/* Resetting Default Box-sizing */

*,
*:before,
*:after {
    Box-sizing: Border-box;
}


/* Optional Styles For Centering Radiobuttons */

.sound-signal Label {
    Display: Inline-flex;
    Align-items: Center;
}

.error-text-container-in-driver-popup {
    margin: 1% auto;
    color: red;
    margin-bottom: -2%;
    text-align: center;
    display: block;
    font-weight: 500;
    font-size: 18px;
}

.driver-popup-container-input-div {
    height: 89%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.driver_inputs_container {
    height: 12%;
    margin: 30px auto
}

.shop-logo-in-shop-setting {
    width: 100px;
    margin-left: 16px;
    border-radius: 10px;
}

.shop-group-popup-inputs {
    outline: none;
    padding: 10px 0px 10px 10px;
    border: 0.3px solid var(--main_navbar_button_text_color);
    height: 40px;
    width: 95%;
}

.shop-group-popup-inputs:hover,
.shop-group-popup-inputs:focus {
    border: 1px solid var(--main_color);
}

.shop-group-popup-sort-input-div {
    width: 50%;
    text-align: right
}

.shop-group-popup-sort-input-div h6 {
    text-align: left;
    margin-left: 11px
}

.shop-group-popup-conatiner-close-icon {

    border-bottom: .3px solid #b4b4b4;
    height: 30px;
    width: 95.5%;
    margin: auto;
    margin-bottom: 30px;

}

.shop-group-popup-header-div {
    width: 100%;
    padding: 0 0 25px 0
}

.shop-group-popup-conatiner-close-icon h6 {
    margin-top: 5%;
    margin-bottom: 2%
}

.shop-group-popup-container-inputs-div {
    display: flex;
    /* margin: 20px 40px 10px 16px; */
    justify-content: space-between;
    width:95.5%;
    margin: auto;
   
}
.shop-group-popup-container-search-box{
width:95.5%;
border: 1px solid #d7d7d7;
margin: auto;
height:50px;
display: flex;
align-items: center; 
justify-content: space-between;
padding-left: 8px;
margin-bottom: 25px;
}
.shop-group-popup-container-radio_button{
width:95.5%;
margin: auto;
margin-bottom: 25px;
display: flex;
 justify-content: space-between;      
}
.external-company-container-radio-buttons{
width:60%;
margin: auto;
display: flex;
 justify-content: space-between;     

}
.shop-group-popup-container-search-box input{
height: 100%;
width: 97%;
border: none;
outline: none;
}
.shop-group-popup-container-search-box input::placeholder{
    color: rgb(183, 183, 183);
  opacity: 1; /* Firefox */
}
.shop-group-popup-container-search-box i{
     color: rgb(159, 158, 158);
    font-size: 19px;
}
.external-company-container-search-input{
    width:35%;
border: .5px solid #d7d7d7;
height:40px;
display: flex;
align-items: center; 
justify-content: space-between;
padding-left: 8px;

}
.external-company-container-search-input input{
height: 100%;
width: 97%;
border: none;
outline: none;
}
 .external-company-container-search-input input::placeholder{
    font-size: 13px;
 }
.external-company-container-search-input input::placeholder{
    color: rgb(183, 183, 183);
  opacity: 1; /* Firefox */
}
.external-company-container-search-input i{
     color: rgb(159, 158, 158);
    font-size: 19px;
}
.external-company-container-serach-box-and-radio-buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-left: 16px;
}
.shop-group-popup-button-key {
    width: 91% !important;
    margin: 0px 0px 15px 16px !important
}

.profile-container-image {
    width: 50%;
    height: 27%;
    border: 1px solid rgb(217, 211, 211);
    /* border: 1px solid rgb(242, 233, 233); */
    margin: 50px auto;
    border-radius: 100px;
}

.profile-container-image img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    box-sizing: initial;
}

.profile-container-input-type-file {
    width: 85%;
    margin: 30px auto;
}

.profile-container-image h6 {
    text-align: center;
    margin-top: 30px;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: none;
}

.inputfile+label {
    font-size: 1.25em;
    font-weight: 300;
    color: white;
    width: 100%;
    outline: none;
    text-align: center;
    height: 50px;
    border-radius: 4px;
    border: .3px solid var(--main_navbar_button_text_color);
    background-color: var(--main_color);
    display: inline-block;
    padding-top: 7px;
    cursor: pointer;
}

.inputfile+label:focus,
.inputfile+label:hover {
    border: 1px solid var(--main_color);
}


/* .inputfile + label * {
	pointer-events: none;
} */

.common-inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: none;
}

.common-inputfile +label {
    font-size: 1.25em;
    font-weight: 300;
    color: white;
    width: 100%;
    outline: none;
    text-align: center;
    height: 50px;
    border-radius: 4px;
    border: .3px solid var(--main_navbar_button_text_color);
    /* background-color: rgb(225, 32, 32); */
    background-color: rgb(50, 177, 186); 
    display: inline-block;
    padding-top: 7px;
    cursor: pointer;
}

.common-inputfile +label:focus,
.common-inputfile +label:hover {
    border: 1px solid rgb(50, 177, 186);
}

.profile-parent-div {
    display: flex;
    justify-content: space-between
}

.profile-container-detail {
    width: 65%;
    border: .1px solid rgb(217, 211, 211);
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: .2px 1px 2px 1px rgb(242, 233, 233);
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.profile-update-details-button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    color: white;
    background-color: #f38321;
    border: none
}

.profile-container-choose-image {
    width: 32%;
    border: .1px solid rgb(217, 211, 211);
    box-shadow: .2px 1px 2px 1px rgb(242, 233, 233)
}

.profile-image-div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.select-dropdown {
    margin-top: -6px;
    margin-left: 3px;
    margin-right: 4px;
    width: 43%;
}

.select {
    width: 100%;
}

.order_detail_main_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    height: 100%;
    margin-top: 10px;
    text-align: center;
}
.order_detail_main_section_1{
      display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    height: 100%;

    /* margin-top: 10px; */
    text-align: center;

 /* margin-bottom: 20px; */
    /* background-color: white; */

}

.order_detail_main_section:last-child button {
    width: 100%;
    height: 40px;
    color: white;
    background-color: var(--main_color);
    border: 1px solid var(--main_color);
}


/* .textfield {
display: flex;
align-items: center;
justify-content: center;
} */


/* body{
    background-color:d#f2f4f6;
} */

.shop_status_parent_div {
    width: 98.5%;
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: scroll;
    padding: 20px;
    border: 1px solid red;
    /* background-color: #f2f4f6 */
}

.shop_status_container_header {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
      background-color: red;
height: 115px;
color: white;
border-radius: 2px
}

.shop_status_length_boxes {
    width: 20%;
    background-color: white;
    border-radius: 5px;
    display: flex;
    padding: 35px 20px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.shop_status_length_boxes:hover{
 /* box-shadow: 1px 1px 10px 1px #e3e2e2, -1px -1px 10px 1px #e3e2e2 */
 /* box-shadow:1px 1px 10px 1px #D5D5D5, -1px -1px 10px 1px #D5D5D5 ; */
 box-shadow:.5px .5px 10px 1px #e3e2e2, -.5px -.5px 10px 1px #e3e2e2;
}

.shop_status_online_box_icon_section,
.shop_status_maintainence_box_icon_section,.shop_status_new_message_box_icon_section {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
 
}

.shop_status_online_box_icon_section i {
    font-size: 50px;
    color: rgb(8, 168, 8)
}
.shop_status_new_message_box_icon_section{
      font-size: 50px;
    color: rgb(45, 45, 255)
}
.shop_status_length_boxes_container_h_tags {
    width: 55%;
    display: flex;
    height: 55px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
 
}

.shop_status_maintainence_box_icon_section i {
    font-size: 50px;
    color: yellow
}

.shop_status_container_main_section {
    border-radius: 5px;
    width: 100%;
    background-color: white;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

}

.shop_status_header_list {
    height: 40px;
    width: 100%;
 

}

.shop_status_main_list {
    height: 85%;
    width: 100%;
    padding: 0px 0px 0px 0px;
    display: flex;
}

.shopStatusHeader_parent_div {
    width: 100%;
    padding-bottom: 10px;
    height: 100%;
}

.shopStatusHeader_container_titles {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
   padding: 0px 14px 0px 55px;
    border-radius: 5px
}

.shopStatusHeader_container_titles p {
    display: flex;
    margin: 0;
    justify-content: center;
    color: #959595;
   width: 25%;
}

.ShopStatusMainList_parent_div {
    width: 100%;
    height: 100%;

}

.ShopStatusMainList_container_list_items {
    display: flex;
    height: 100%
}

.ShopStatusMainList_container_shop_logo {
    width: 60px;
    padding: 10px;
    height: 60px
}

.ShopStatusMainList_container_shop_logo img {
    width: 100%;
    height: 100%;
    border-radius: 20px
}

.ShopStatusMainList_container_data {
    display: flex;
    width: 95%;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
    align-items: center;
}
.ShopStatusMainList_container_data a{

              text-align: center;
              color: black;
              text-decoration: none
      
}

.ShopStatusMainList_container_data p {
    display: flex;
    justify-content: center;
    margin: 0
}

.ShopStatusMainList_last_p {
    border-radius: 5px;
    padding: 2px;
    width: 10%
}

.open-close-time-input:focus {
    border: 1px solid var(--main_color);
}

.open-close-time-input {
    border: none;
}

.open-close-container-div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    padding-top: 8px;
    width: 100%
}

.open-close-parent-div {
    height: 100%;
    display: flex;
    flex-direction: column;
 
}

.open-close-container-day-of-week {
    width: 12.5%;
    padding: 2px 2px 2px 10px;
    display: flex;
    align-items: center
}

.open-close-container-day-of-week p {
    margin: 0;
    width: 100%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.open-close-container-save-swith {
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: "center";
    margin: 0;
}

.open-close-general-style {
    margin: 0;
    width: 12.5%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center
}

.open-close-general-style button {
    border: none;
    color: green;
    border-radius: 5px;
    background-color: inherit;
    font-size: 18px
}

.open-close-general-width {
    width: 12.5%;
}

.open-close-hr {
    width: 90%;
    color: black;
    margin: auto;
}

.open-close-container-day-of-week i {
    font-size: 18px;
}

.web_payment_container_payment_methods_box {
    width: 100%
}

.web_payment_container_payment_methods_box h5 {
    color: grey;
    margin: 0px 0px 15px 0px
}

.web_payment_container_payment_methods_box h6 {
    margin: 0;
    padding: 20px;
    font-size: 20px;
    color: #525252;
}

.web_payment_container {
    border: .2px solid rgb(153, 152, 152);
    display: flex;
    justify-content: space-between;
    border-Radius: 3px;
    margin-bottom: 50px;
}

.web_payment_container_delivery_collection_box {
    display: flex;
    padding: 20px
}

.web_payment_h5_sure_charge {
    color: grey;
    margin: 0px 0px 15px 0px
}

.web_payment_container_visible_chechbox {
    display: flex;
    align-items: center;
    height: 45px
}

.web_payment_container_sure_charge_box {
    border: .2px solid rgb(153, 152, 152);
    padding: 40px 20px;
    border-radius: 3px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.web_payment_mui_box {
    display: flex;
    width: 64%;
    justify-content: space-between
}

.web_payment_try_again_button {
    border: 2px solid var(--main_color);
    background-color: var(--main_color);
    border-radius: 3px;
    width: 150px;
    color: white;
    height: 40px
}

.general_setting_carrier_bag {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.time_gap_container_raddio_button {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: auto
}

.time_gap_radio_group {
    width: 49%;
    display: flex;
    align-items: flex-start
}

.time_gap_delivery_collection_title_styles {
    margin: 0;
    width: 10%;
    display: flex;
    align-items: center
}

.time_gap_container_delivery_collection_dropDown {
    width: 90%;
    display: flex;
    justify-content: space-between
}

.time_gap_container_expire_time {
    display: flex;
    width: 72%;
    justify-content: flex-end;
    padding-right: 10px
}

.time_gap_container_expire_time p {
    width: 27%;
    margin: 0;
    display: flex;
    align-items: center
}

.time_gap_container_hour_minute {
    width: 33%;
    display: flex;
    justify-content: "space-between",
}

.time_gap_container_hour_minute_dropDown {
    width: 49%;
    display: flex;
    justify-content: flex-start;
}

.time_gap_container_hour_minute_dropDown p {
    margin: 0;
    display: flex;
    align-items: center;
    width: 15%;
    margin-left: 3px
}

.box_shadow {
    /* box-shadow: 3px 3px 4px 3px rgb(244, 243, 243), -3px -3px 2px 3px rgb(239, 239, 239) */
}

.delete-popup {
    box-shadow: 3px 3px 4px 3px rgb(233, 233, 233), -3px -3px 2px 3px rgb(236, 235, 235);
  width: 400px;       
display: flex;
flex-direction: column;
align-items: center;
padding: 18px 5px 6px 5px
}
.delete_customer_popup{
  background-color: white;
  width: 100%; 
  height: 150px;   
  border-radius: 3px;   
display: flex;
flex-direction: column;
align-items: center;
/* justify-content: space-between; */
padding: 30px 5px 6px 5px
}
.delete_customer_popup_container{
      width: 37%; 
  height: 150px; 
  position: relative;
}
.delete_customer_popup_container_icon{
    position: absolute;
    right: 5px;
    top: 3px;

}
.delete_customer_popup_container_icon i{
    cursor: pointer;
    font-size: 23px;
}

.container-maintenance-message-list:hover {
    background-color: var(--main_color);
    color: white;
    border-bottom: 1px solid var(--main_color);
}

.container-maintenance-mode-checkbox {
    display: flex;
    align-items: flex-end
}

.container-maintenance-reasones {
    width: 64%;
    z-index: 11
}

.maintenance-container-message-inputs {
    width: 64%;
    display: flex;
    justify-content: space-between
}

/* .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -ms-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    opacity: 1;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
} */


/* 
.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    content: '';
}

.carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none;
}

.carousel .control-prev.control-arrow {
    left: 0;
}

.carousel .control-prev.control-arrow:before {
    border-right: 10px solid rgb(0, 60, 255);
}

.carousel .control-next.control-arrow {
    right: 0;
}

.carousel .control-next.control-arrow:before {
    border-left: 35px solid red;
}

.carousel-root {
    outline: none;
}

.carousel {
    position: relative;
    width: 100%;
}

.carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
}

.carousel .carousel {
    position: relative;
}

.carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
}

.carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
}

.carousel .thumbs {
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
}

.carousel .thumb {
    -webkit-transition: border 0.15s ease-in;
    -moz-transition: border 0.15s ease-in;
    -ms-transition: border 0.15s ease-in;
    -o-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
}

.carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #333;
}

.carousel .thumb img {
    vertical-align: top;
}

.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height 0.15s ease-in;
    -moz-transition: height 0.15s ease-in;
    -ms-transition: height 0.15s ease-in;
    -o-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in;
}

.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column;
}

.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
}

.carousel .slider.animated {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
}

.carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
}

.carousel .slide .legend {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
}

.carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
}

@media (min-width: 960px) {
    .carousel .control-dots {
        bottom: 0;
    }
}

.carousel .control-dots .dot {
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -ms-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff;
}

.carousel:hover .slide .legend {
    opacity: 1;
}   */

.prev,
.next {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
}

.next {
    margin-left: 5px;
}

.prev {
    margin-right: 5px;
}

.prev:hover,
.next:hover {
    color: white;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.image-container {
    vertical-align: middle;
    display: inline-block;
    width: 50%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.image {
    height: 100px;
    width: 100px;
    font-size: 25px;
    background: blue;
    margin: 10px;
    display: inline-block;
    line-height: 100px;
}

.main {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.carousel {
    margin-right: 35px;
}

.rec.rec-arrow {
    border-radius: 30%;
    outline: none;
    background-color: var(--main_color);
}


/* round buttons on hover */

.rec.rec-arrow:hover {
    border-radius: 50%;
}


/* hide disabled buttons */

.rec.rec-arrow:disabled {
    visibility: hidden;
}


/* disable default outline on focused items */


/* add custom outline on focused items */

.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.image-default-size {
    margin: 0;
    color: grey;
    font-size: 13px;
}
.basket_product_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.basket_product_title_container {
    display: flex;
    align-items: center;

}

.basket_product_title_container i {
    color: var(--primary_color);
    font-size: 20px;
    margin-right: 5px;
    cursor: pointer;
}

.basket_product_title_container i:active {
    color: var(--primary_light);
}

#product_number_container {
    background-color:rgb(228, 228, 228) ;
    border-radius: 200px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--product_title_color);
    margin-right: 5px;
}

.basket_sublist_container {
    padding: 0 0px 0 29px;
}

.basket_sublist_container div {
    display: flex;
    justify-content: space-between;
}

.basket_sublist_container h6 {
    margin: 0;
    color: var(--product_title_color);
}
.order_dateil_first_box{
      /* border: .5px solid grey; */
              /* height: 100%; */
          
              /* background-color: white; */
              /* padding: 15px; */
         
              /* margin-bottom: 15px */
}

.order_detail_ocntainer{
      display: flex;
                height: 35px;
                align-items: center;
                justify-content: space-between;
                color: #5d5c5c;
             
                
}


.order_detail_ocntainer p{
     margin: 0;
                  font-size: 18px;
                  display: flex;
                  justify-content: flex-start
}
.order_detail_ocntainer_1{
  padding-bottom: 10px;
    padding-top: 10px;
color: #5d5c5c
}
.order_detail_ocntainer_1 p{
        margin: 0;
 font-size: 18px;

}
.countaining-div-11{
height: calc(100vh - 160px);


}
.promotion_countaining-div{
    height: calc(100vh - 260px);
    border: 1px solid red;
}
.web-setting-container-Carousel{
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
position: absolute
}
.menu-list-container{
      background-color: white;
          padding: 3px;
}

.menu-list-container-inputs{
display: flex;
width: 87%;
justify-content: space-between
}
.menu-list-container-edit-box-icons{
width: 12%;
height: 40px;
display: flex;
justify-content: space-between
}
.menu-list-container-icon{
width: 45%;
heigth: 100%;
border: .2px solid grey;
display: flex;
justify-content: center;
align-items: center;
border-radius:3px ;
}
.menu-list-fa-check{
    font-size: 23px;color: green
}
.menu-list-fa-times{
     font-size: 23px;color: red
}
.menu-list-continer-title{
 width: 95%;
 display: flex;
 align-items: center;
}
.menu-list-continer-title-1{
 overflow: hidden;
 text-align: ellipsis;
}
.menu-list-continer-title h6 {
 overflow: hidden;
text-overflow: ellipsis;
width: 98%;
font-size: 14px;
}
.menu-list-continer-title pre{
  width: 50%;
border: 1px solid blue;
}
.menu-list-continer-price-and-delete-and-edit-icons{
    
display: flex;
align-items: center;
justify-content: space-between;


}
.menu-list-continer-price-and-delete-and-edit-icons-1{
display: flex;
align-items: center;
justify-content: flex-end;

}
.menu-list-continer-price-and-delete-and-edit-icons-1 p{
    width: 70px;

}
.menu-list-continer-price-and-delete-and-edit-icons i, .menu-list-continer-price-and-delete-and-edit-icons-1 i{
    width: 25px;
    text-align: center;

}

.tooltip-container{
    display: flex;
justify-content: space-between;

}
.menu-list-container-cancel-and-ok-button{
     width: 50%;
justify-content: space-between;
display: flex;
margin: 22px 20px 10px 30px;
align-items: center
}
.menu-list-container-cancel-and-ok-button :first-child{
    border: 1px solid green;
    height: 36px;
width: 48%;
border: 1px solid #b4b4b4;
color: black;
border-radius: 3px

}
.menu-list-container-cancel-and-ok-button :last-child{
       height: 36px;
width: 48%;
border: 1px solid red;
background-color: red;
color: white;
border-radius: 3px
}
.container-image-box{
      height: 200px;
margin-bottom: -60px;
margin-top: 10px;
width: 28%;
display: flex;
justify-content: center
}
.container-image{
     width: 70%;
height: 100%;
border-radius: 100px;
}
.container-image img{
      width: 100%;
height: 100%;
border-radius: 100px;
}
.container-picture-selector{
      display: flex;
 justify-content: space-between
}
.container-input-select{
      width: 28%;
display: flex;
flex-direction: column;
margin-top: 20px
}
.container-unique-id{
       width: 100%;
padding: 7px 0px 12px 10px;
background-color: rgb(246, 242, 242);
}
.driver-container-textfield{
      display: flex;
width: 100%;
justify-content: space-between;

}
.driver-container-textfield-2{
     display: flex;
width: 64%;
justify-content: space-between
}
.container-customers-list{
height: calc(100vh - 258px);
overflow-y: auto
}

.customers-container-header{
     width: 100%;
height: 60px;
display: flex;

align-items: center;
border-bottom: 3px solid grey
}
.customers-container-header h6{
    margin: 0;
display: flex;
flex: 1;
justify-content: center;
font-size: 15px;

}
.customers-container-list{
display: flex;
align-items: center;
padding:20px 5px  18px 5px;
 text-align: center; 
 justify-content: space-between;

}
.customers-container-list h6{
width: 11.5%;
margin: 0;
font-size: 14.5px;
display: block;
 word-wrap: break-word;
text-align: center;
                    /* overflow-wrap: anywhere; */

}

.customers-container-list input{
    width: 12%;
margin: 0;
display: block;
 word-wrap: break-word;
 outline: none;

/* text-align: center; */
border: .3px solid #b4b4b4;
}

.customers-container-list input:focus{
   border: 1px solid var(--main_color); 
}
.customer_container_full_name{
    width: 13%;
    display: flex;
    flex-direction: column;
/* border: 1px solid red; */

}
.customer_container_full_name div{
    display: flex;
  margin-top: -18px;
    /* justify-content: space-between; */
}
.customer_container_full_name input{
    width: 100%; 

}

.customer_container_full_name p{
    margin: 0;
   
    text-align: left;
}
.customer_container_full_name i{
margin-top: 3px;
margin-left: 5px;
cursor: pointer;
}
.container-Pagination{
     display: flex;
width: 100%;
justify-content: center;

margin-top: 10px;
}
.edit-product-rolesList{
columns: 3 auto;
margin-top: var( --normal_margin_top)
}
.sm-m textarea{
    height: var(--button_height);
  border: .2px solid rgb(153, 152, 152);
     outline: none;
  border-radius: 2px;
  padding: 2.5%;
}
.sm-m textarea:focus{
        border: 1px solid var(--main_color);
}
@keyframes color-change {
  0% { color: red; }
  50% { color: blue; }
  100% { color: red; }
}
.badge20{
border: 1px solid red;
background-color: red;
}
/* p {
  animation: color-change 1s infinite;
} */
.badge{
      animation: color-change 1s infinite;
}
@keyframes color-change {
  0% { background-color: white; }
   30% {background-color:#fdc89a }
    50% {background-color:#f6b780 }
     80% {background-color:#fab070 }
  100% { background-color: var(--main_color) }
}
.badge1{
     animation: color-change1 1s infinite;
}

@keyframes color-change1 {
  0% { background-color: white; }
   30% {background-color:rgb(247, 198, 198) }
     50% {background-color:rgb(250, 164, 164) }
     75% {background-color:rgb(252, 119, 119) }
    90% {background-color:rgb(248, 99, 99) }
  100% { background-color: rgb(250, 35, 35)}
}

.badge2{
     animation: color-change2 1s infinite;
        width: 45px;
    height: 45px;
    /* background-color: #fa92d4; */
    border-radius: 100px;
    /* background-color: rgb(246, 241, 194); */
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes color-change2 {
  0% { background-color: rgb(248, 248, 248); }
   30% {background-color:rgb(249, 71, 71) }
     50% {background-color:rgb(245, 51, 51) }
     75% {background-color:rgb(243, 22, 22) }
    90% {background-color:rgb(254, 46, 46) }
  100% { background-color:red}
}
.badge3{
     animation: color-change3 1s infinite;
        width: 45px;
    height: 45px;
    /* background-color: #fa92d4; */
    border-radius: 100px;
    /* background-color: rgb(246, 241, 194); */
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes color-change3 {
  0% { background-color: rgb(248, 248, 248); }
   30% {background-color:rgb(255, 197, 126) }
     50% {background-color:rgb(255, 197, 126) }
     75% {background-color:rgb(254, 192, 116) }
    90% {background-color:rgb(252, 190, 115) }
  100% { background-color:rgb(254, 189, 109)}
}

.badge5{
     animation: color-change5 1s infinite;
        width: 45px;
    height: 45px;
    /* background-color: #fa92d4; */
    border-radius: 100px;
    /* background-color: rgb(246, 241, 194); */
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes color-change5 {
  0% { background-color: white; }
   30% {background-color:#fba357 }
     50% {background-color:#fd9439 }
     75% {background-color:#f88119}
    90% {background-color:var(--main_color) }
  100% { background-color:#ff7700 }
}


body{
    color: #48ba4e;
}
.container_123{
     columns: 5;
                   -webkit-columns: 5;
                   -moz-columns: 5;
                   width: 40px;
}

.container-image-delete-icon{
      width: 25px;
                    height: 25px;
                    display: flex;
                    position: absolute;
                    border-radius: 100px;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(213, 209, 209, 0.7);
                    right: 3px;
                    top: 3px;
};

.container-image-delete-icon i{
      font-size: 20px;         
color: red;
margin-left: 1px

}
/* .profile_image{
 opacity: 0.5;
        filter:opacity(100);
}
.profile_image:hover{
     background: rgba(0, 255, 255, 0.5);
     opacity:1.0;
  filter:opacity(40)
} */

.profile-container-image i{
    position: absolute;
    font-size: 37px;
    left: 42%;
    top: 42%;
    cursor: pointer;
    z-index: 300000000;
color: white;
}
.profile-container-delete-icon-image{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
     z-index: 2;
}

.profile-container-delete-icon-image:hover{

color: white;
}

.product_open_close_time_popup_container{
     width: 45%;
          height: 90%;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 1%
}
.product_open_close_container_header{
     border-bottom: 1px solid black;
            width: 100%;
            text-align: center
}
.product_open_close_container_header i{
     cursor: pointer;
              position: absolute;
              right: 8px;
              top: 8px;
}
.product_open_close_time_container_dropdown{
       width: 100%;
            padding: 10px 20px;
            overflow-y: auto;
            height: 80%
}
.product_open_close_time_container_any_day{
      width: 100%;
              display: flex;
              justify-content: space-between;
              height: 65px
}
.product_open_close_time_container_time_selector{
     display: flex;
                width: 65%;
                justify-content: space-between
}
.product_open_close_time_container_save_button{
     width: 100%;
            padding: 0px 20px
}
.product_open_close_time_container_save_button button{
     width: 100%;
              height: 45px;
              margin: auto;
              outline: none;
              background-color: var(--main_color);
              border: none;
              color: white;
              border-radius: 3px
}
.container_availability_dropdown{
     display: flex;
                  justify-content: space-between;
                  width: 100%
}
.container_availability_dropdown button{
width: 20%;
outline: none;
color: white;
background-color:var(--main_color);
border: 1px solid var(--main_color)
                   
}
.promotion_edit_container{
    height: calc(100vh - 360px);
}
.date_picker{
  height: var(  --button_height);
  width: 100%;
  outline: none;
  border:1px solid var(--main_navbar_button_text_color) ;
  border-radius: 2%;
  padding-left: 2%;
}
.date_picker:hover , .date_picker:focus,.date_picker_sales:hover,.date_picker_sales:focus{
      border:1px solid var(--main_color) ;
}
.calendarClassName{
    width: 100%;
}
body{
    color: #e5e5e5;
}
.shop_status_length_boxes-1{
     width: 39%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              cursor: pointer;
              padding-right: 45px
}
.shop_status_length_boxes-1 p,.shop_status_length_boxes_online_1 p,.shop_status_length_boxes_maintanance_1 p{
    margin: 14px 0px 0px 5px; font-size: 13px
}

.shop_status_length_boxes_online_1{
      cursor: pointer;
width: 22%;
padding: 25px 0px;
display: flex;
justify-content: center;
align-items: center
}

.shop_status_length_boxes_online_1 div{
      display: flex;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;
border-right: 1px solid white;
border-left: 1px solid white

}

.shop_status_length_boxes-1:hover{
 
    background-color: rgba(232, 17, 17, 0.6);
}
.shop_status_length_boxes_maintanance_1{
     align-items: center;
cursor: pointer;
width: 39%;
display: flex;
justify-content: flex-start;
padding-left: 45px
}
.customer-container-save-and-close-icon{
     width: 70px;
padding-right: 5px;
color: grey;
padding-left: 5px;
display: flex;
justify-content: space-between;
height: 28px
}
.customer-container-save-and-close-icon div{
      width: 49%;
height: 100%;
border: .2px solid grey;
display: flex;
justify-content: center;
align-items: center
}

.customer-container-delete-and-edit-icon{
  width: 70px;
padding-right: 5px;
padding-left: 10px;
color: grey;
display: flex;

justify-content: space-between
}
.customer-container-delete-and-edit-icon i,.customer-container-save-and-close-icon i{
    cursor: pointer;
}
.customer_container{
      width: 99%;
        margin: auto;
        overflow-y: auto;
        margin-left: 14px;
}
.customer-container-div{
     width: 99.3%; height: 100%
}
.container_right_bar{
      width: 10px;
                  height: 99%
}
.ShopStatusMainList_container_data div {
   width: 25%;
display: flex;
align-items: center;
justify-content: center;
}
.ShopStatusMainList_container_data div p{
      height: 35px;
display: flex;
align-items: center;
justify-content: center;
margin: 0;
border-radius: 2px;

}
.ShopStatusMainList_p_tags{
    width: 25%;
}
.ShopStatusMainList_p_tags span{
    color: green; margin-right: 5px
}
.worksation_status_container_header{
     background-color:rgb(188, 186, 186);
          height:  40px;
          display: flex;
          margin: 0px 16px;
          margin-Top: 10px;
          /* // padding: " 0 14px", */
          align-items:  center;
          justify-content: space-between
}
.worksation_status_container_header h6{
      margin: 0;
            width: 14%;
            text-align: center;
            /* color: gray;
            font-weight: 900; */
}
.worksation_status_container_list{
      height: 40px;
display: flex;
margin:  0 16px;
margin-top: 10px;
align-items: center;
justify-content: space-between
}
.worksation_status_container_items{
 background-color:  var(--main_navbar);
height: 100%;
display: flex;
flex: 1;
align-items:  center;
justify-content: space-between;
}
.worksation_status_item_parameters{
      margin: 0;
width: 16%;
text-align: center;
}
.worksation_status_item_container_version_status{
    display: flex;
    align-items: center;
    justify-content: center;
}
.worksation_status_item_container_version_status h6{
      display: flex;
align-items: center;
justify-content: center;
margin: 0;
width: 55%;
text-align: center;
font-weight: bolder
}
.worksation_status_item_container_status{
      display: flex;
align-items: center;
justify-content: center;
}
.worksation_status_item_container_status h6{
     display: flex;
                          align-items: center;
                          justify-content: center;
                          text-align: center;
                          width: 68%;
                          margin: 0;
                          font-weight: bolder;
                              height: 32px;
                          border-radius: 3px
}
.worksation_status_container_result_button{
       width: 100px;
                      height: 100%;
                      margin-left: 10px
}
.worksation_status_container_result_button button{
     width: 100%;
border-radius: 2px;
border: 1px solid var(--main_color);
color: white;
height: 100%;
background-color:var(--main_color)
}
.demo {
  width: 100px;
  min-height: 20px;
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid black;
}
.shop_message_support_list_container{
     z-index:  1000;
          overflow-y: auto;
          width: 46%;
}
.shop_message_support_container{
    display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 97%;
            margin: auto;

            height: 100%
}
.shop_message_support_container_header{
      width: 100%;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 20px;
}
.shop_message_support_container_header h5{
    color: rgb(113, 112, 112)
}
.shop_message_support_container_list{
      display: flex;
              flex: 1;
              flex-direction: column;
}
.shop_message_support_container_items{
    display: flex;
                        justify-content: space-between;
                        border: 1px solid #C8D0D5;
                        flex-direction: column;
                        margin-bottom: 30px;
                        border-radius: 5px
}
.shop_message_support_container_items_header_section{
     display: flex;
                          width: 100%;
                          border-bottom: 1px solid #CDD6DB;
                          align-items: center;
                          padding: 8px;

               
                          background-color: #dfebf3;
}
.shop_message_support_container_items_image_section{
     width: 40px;
                            height: 40px;
                            border-radius: 50px;
}
.shop_message_support_container_items_image_section img{
      width: 100%;
                              height: 100%;
                              border-radius: 50px;
}
.shop_message_support_container_items_info_section{
      display: flex;
                            flex: 1;
                            justify-content: space-between;
                            align-items: center;
                            padding-left: 10px;
                            padding-right: 10px
}
.shop_message_support_container_items_info_section p{
    margin: 0;
    color: grey
 }
 .shop_message_support_container_items_question_section{
     width: 100%; padding: 15px
 }
 .shop_message_support_h6{
     line-height: 25px;
                            color: rgb(93, 92, 92);
 }
 .shop_message_support_container_answered_section{
     display: flex;
                              justify-content: space-between;
                              border: 1px solid #C8D0D5;
                              flex-direction: column;
                      
                              border-radius: 5px;
                              margin-top: 30px;
 }
 .shop_message_support_container_answer_header{
      display: flex;
width: 100%;
border-bottom: 1px solid #CDD6DB;
align-items: center;
padding: 8px;
background-color: #F2F4F6;
 }
 .shop_message_support_container_answer_header_info{
      display: flex;
flex: 1;
justify-content: space-between;
align-items: center;
padding-left: 10px;
padding-right: 10px
 }
 .shop_message_support_container_answer_header_info p{
     margin: 0;
     color: grey
 }
 .shop_message_support_container_answer_MuiThemeProvider{
       display: flex;
flex: 1;
align-items: center;
padding: 13px 5px;
justify-content: space-between;
border-radius: 3px
 }
 .shop_message_support_container_send_image{
          height: 35px;
display: flex;
align-items: center;
justify-content: center;
 }
  .shop_message_support_container_send_image img{
    height: 80%
  }
  .shop_message_support_container_typed_answer{
     display: flex;
align-items: center;
padding: 5px
  }
  .shop_message_support_container_typed_answer_div{
       width: 100%;
    padding: 10px
  }
   .shop_message_support_container_typed_answer_div h6{
     line-height: 25px;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    color: rgb(93, 92, 92)
   }
   .shop_message_support_container_typed_answer i{
     color: var(--main_color);
    cursor: pointer
   }
   .shop_message_support_container_answer_MuiThemeProvider2{
      display: flex;
    margin-top: 25px;
    flex: 1;
    border: .7px solid #B9BDBF;
    align-items: center;
    padding: 13px 5px;
    justify-content: space-between;
    border-radius: 3px
   }

   .promotion_list_container_header{
      margin-top: 10px;
   }
   .promotion_list_container_header div{
    display: flex;
   margin-right: 186px;

    justify-content: space-between;
  
   }
   .promotion_list_container_header div h6:first-child{
    text-align: start;
   }
   .promotion_list_container_header div h6{
    flex: 1;
    text-align: center;
   }
  .submenu-edit-container-header{
    padding-right: 346px;
      display: flex;

  }
  .submenu-edit-container-header h6{
    margin: 0;
  }
   .submenu-edit-container-header div{
   display: flex;
   /* flex: 1; */
   text-align: left;
   justify-content: space-between;
   width: 35%;
   }   .submenu-edit-container-header div h6{
    /* flex: 1; */
   }
    .promotion_list_headercontainer_p{
  display: flex;
      flex: 1;
       align-items: center;
      justify-content: center;
      margin: 0;
    }
    .promotion_list_headercontainer_first_p{
          display: flex;
      flex: 1;
       align-items: center;
      justify-content:flex-start;
      margin: 0;
    }
   


.react-calendar { 
 width: 400px;
 max-width: 100%;
 background-color: #fff;
 color: rgb(165, 165, 165);
 border: none;
 border-radius: 8px;
 /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
 font-family: Arial, Helvetica, sans-serif;
 line-height: 1.125em;
}
.react-calendar__navigation button {
 color: grey;
 min-width: 44px;
 background: none;
 font-size: 16px;
 margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
 background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
 background-color: #f0f0f0;
}
abbr[title] {
 text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 color: #4fd55f;
 border-radius: 6px;
}
.react-calendar__tile--now {
 background: #e9fff6;
 border-radius: 6px;
 font-weight: bold;
 color: #4fd55f;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
 background: #e9fff6;
 border-radius: 6px;
 font-weight: bold;
 color: #4fd55f;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
 background: #f8f8fa;
}
.react-calendar__tile--active {
 background:#4fd55f;
 border-radius: 6px;
 font-weight: bold;
 color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
 background: #4fd55f;
 color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
 background-color: #f8f8fa;
}
.react-calendar__tile--range {
 background: #e8faea;
 color: #4fd55f;
 border-radius: 0;

}
.react-calendar__tile--rangeStart {
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 border-top-left-radius: 6px;
 border-bottom-left-radius: 6px;
 background: #4fd55f;
 color: white;
}
.react-calendar__tile--rangeEnd {
 border-top-left-radius: 0;
 border-bottom-left-radius: 0;
 border-top-right-radius: 6px;
 border-bottom-right-radius: 6px;
 background: #4fd55f;
 color: white;
}
.customer-container-searchbox{
width: 100%;
position: relative;
margin-top: 45px;
margin-bottom: 25px;
border-radius: 2px;

}
.customer-searchbox-container-search-input-and-button{
height:50px;
display: flex;


justify-content: space-between;
align-items: center;

}
.customer-searchbox-container-search-input-and-icon{
     width: 74%;
padding: 0px 10px;
    height: 100%;
    /* border-radius: 2px; */
 display: flex;
 align-items: center;
 border: 1px solid #d7d7d7;

}
.customer-searchbox-container-search-input-and-icon i{
    color: rgb(159, 158, 158);
    font-size: 19px;

}
.customer-searchbox-container-search-input-and-button input{
    height: 100%;
    display: flex;
    flex: 1;
    border: none;
    padding: 10px;
    outline: none;
    padding: 10px;
}
.customer-searchbox-container-search-input-and-button input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(183, 183, 183);
  opacity: 1; /* Firefox */
}
.customers-search-button{
        height:100%;
        width: 12%;
          border: none;
          background-color: var(--main_color);
          color: white;
          /* border-radius: 2px; */
          margin-left: -6px;
}
.customers-export-to-excel-button{
    width: 13.5%;
     border: none;

          color: white;
            height:100%;
            background-color: rgb(51, 134, 250);
}
.customers-container-radio-bottons{
    display: flex;
    justify-content: space-between;
  padding: 0px 0px 0px 25px;
}
.customers-container-search-type{
    padding: 10px 6px 20px 10px;
    margin-top: 15px;
   border: 1px solid #cbcaca;
   border-radius: 3px;
   z-index: 3333333;
   position: absolute;
   width: 100%;
   background-color: white;
   /* background-color: #e5e5e5; */
}
/* .customers-container-search-type p{
    margin: 0;
} */
.nomber_of_prints_container{

    background-color:rgb(227, 226, 226) ;
     margin-top: 15px;
    margin-bottom: 10px;
    height: var(--button_normal_container_height);
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 100px;

}
.nomber_of_prints_container p{
    margin: 0;
  font-size: 17px;
}
.number_of_prints_container_sale_method{
    display: flex;

    justify-content: space-between;
    align-items: center;
        padding:0px 93px 0px 100px;
height: 80px;
} 
 .number_of_prints_container_sale_method h5{
    margin: 0;
text-align: center;
width: 10%;
color: grey;
   
 }
.number_of_prints_container_minus_plus{
    display: flex;
    align-items: center;

    align-items: center;
    justify-content: space-between;
}
.number_of_prints_container_minus_plus h5{
    margin: 0;
    margin: 0px 20px 0px 20px;
    width: 20px;
}
.number_of_prints_container_icon{
    width: 45px;
    height: 45px;
    background-color: var(--main_color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;    cursor: pointer;
}

.number_of_prints_hr{
    width: 100%;
    margin: auto;
    color: rgb(160, 160, 160)
}
.nomber_of_prints_container_workStation_dropDown{
    width:45%;
    padding-left: 92px; 
    margin-top: 30px;
  margin-bottom: 50px; 
}
.nomber_of_prints_container_workStation_dropDown h6{
    color: #747474;


}

/* WORK STATION MAIN BUTTONS */
.main-buttons-container{
width: 670px;
margin: auto;
  margin-top:0px;
columns: 3;
  display: flex;
    flex-wrap: wrap;
/* row-gap: 3; */
    justify-content: space-between;
margin-bottom: 100px; 

/* height: 100%; */
}

.main-buttons-container-any-button{
    border: 1px solid rgb(238, 238, 238);
    width: 150px;
 
    margin-bottom: 25px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding:8px 5px 5px 5px ;
    padding-bottom:10px;
    cursor: pointer;
    box-shadow: 3px 3px 7px 1px rgb(221, 221, 221),-1px -1px 3px 1px rgb(231, 230, 230);
}
/* .main-buttons-container>.main-buttons-container-any-button{
margin: 8px;

} */
 .main-buttons-container-any-button img{
    width: 100%;
height: 100px;
border: none;
 }
  .main-buttons-container-any-button h6{
    margin: 0;
    font-size: 14px;
  }
  .edit-main-button-container-close-icon{
    position: relative;
    width: 100%; 
  }
  .edit-main-button-popup-container{
      width: 35%;
          padding-top: 0px;
          height: 85%;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
  }
    .edit-main-button-container-close-icon i{
        position: absolute;
        top: 8px;
        right: 10px;
        font-size: 20px;
        cursor: pointer;
    }
.work-station-main-button-container-orginal-name{
  
display: flex;
align-items: center;
margin-top: 20px;
width: 100%;
justify-content: center;

padding: 0px 10px;
}
.edit-main-button-container-close-icon h5{
    margin: 0;
}
.work-station-main-button-container-change-image-section{
   width: 100%;

height: 200px;
    margin: 10px auto 40px auto;
   display: flex;
   flex-direction: column;
   align-items: center;
padding: 0px 10px;


}
.work-station-main-button-container-change-image-section img{
    height: 100%;
 width: 95%;
 height: 200px;


}
.work-station-main-button-container-image{
      position: relative;
     width: 100%;
     height: 100%;
display: flex;
align-items: center;
  justify-content: center;
     z-index: 2;
   

}
.work-station-main-button-container-change-image-input{
    width: 95%;
    margin: auto;
    margin: 15px auto;
    margin-bottom: 40px;

}
.work-station-main-button-container-input{
/* width: 100% */
   width:100%;

padding: 0px 10px;
display: flex;
margin-top: 10px;
flex-direction: column;
justify-content: center;
} 
.work-station-main-button-container-change-image-section p{
    text-align: left;
     margin: 0;
    color: grey;
    font-size: 12px;
margin-top: 5px;
     width:95%;
}
.work-station-main-button-container-input h6{
    margin: 0;
margin: auto;
     width: 95%;
margin-bottom: 6px;
}


.work-station-main-button-container-checkbox{
  width: 95%;
padding: 0px 0px 0px 11px;
    margin-top: 10px;
    display: flex;
justify-content: space-between;
align-items: center;

}
.work-station-main-button-FormGroup{
     height: 100%;
     width: 49%;

}
.work-station-main-button-container-save-button{
margin-top: 40px;
width: 100%;
/* height: 50px; */
display: flex;
justify-content: center;
padding: 0px 10px 0px 10px;

}
.work-station-main-button-container-save-button button{
     height: 45px;
     width:95%;
    margin: auto;
    border: none;
    border-radius: 2px;
    background-color: var(--main_color);
    color: white;
    margin-bottom: 5px;
}
.entry-exit-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
 /* height: 55px; */
    margin-top:16px;

}
.entry-exit-container-main{
margin-top: 10px;
height: calc(100vh - 323px);  

}
.entry-exit-container-main1{
margin-top: 10px;
height: calc(100vh - 373px);


}
.entry-exit-container-main-section{
    display: flex;
    justify-content: space-between;
    padding:8px 14px;
    width: 89%;
    align-items: center;
    background-color: var(--main_navbar);     
}
.entry-exit-container-save-button{
      height: 100%;
      width: 10%;
      display: flex;
      align-items: center;

}
.entry-exit-container-save-button button{
         height: 55px;
         border: none;
       width: 100%;      background-color: var(--main_color);
       color: white;
}
.entry-exit-container-icons{
    width: 3%;
 display: flex; 
    justify-content: flex-end;

}
.entry-exit-container-icons i{
      cursor: pointer;
      font-size: 17px;
    color: var(--main_list_item_trash_color);
}
.entry-exit-container-container-inputs-datepicker{
    display: flex;
    width: 97%;
    align-items: center;
    justify-content: space-between;

}
.entry-exit-container-container-employee-name{
    display: flex;    width: 22.7%;
    align-items: center;
}
.entry-exit-container-container-employee-name p{
    margin: 0;
     word-wrap: break-word;

                      overflow-wrap: anywhere
}
.entry-exit-container-container-datepicker{
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left:10px ;
}
.entry-exit-container-container-inputs{
 display: flex;
    width: 26%;
    justify-content: space-between;
}
.entry-exit-container-container-inputs input{
    width: 47%;
    padding-left: 2%;
    height: 40px;
    outline: none;
 border:.3px solid var(--main_navbar_button_text_color) ;
}
.entry-exit-date_picker{
     border:.3px solid var(--main_navbar_button_text_color) ;
     width: 100%;
}
.entry-exit-input:hover,.entry-exit-input:focus{
    border: 1px solid var(--main_color);
}
.entry-exit-disable-input{
    text-align: center;
}
.entry-exit-container-container-datepicker input{
    width: 95%;
}

.entry-exit-container-header{
    display: flex;
    justify-content: space-between;

}

.entry-exit-commn-styles h6,.entry-exit-commn-styles p{
    flex: 1;
    margin: 0;
    text-align: center;
}
.entry-exit-header-container-inputs-datepicker-titles{
width: 93% ;
    display: flex;

    justify-content: space-between;
}
.entry-exit-container-name-text{
       width: 22.7%;
       text-align: left;
  
}
.entry-exit-container-checkin-text{
      width: 23.5%;
       
}
.entry-exit-container-duration-text{
     width:13%;
 
}
.entry-exit-header-container-inputs-datepicker-titles p{

margin: 0;

}
.entry-exit-delete-icon-header{
    width: 15.8%;
    margin: 0;

}
.enter-exit-container-buttons-date-picker{
   margin: 25px 16px 5px 16px;
  display: flex;
align-items:flex-end;
justify-content: space-between; 
}
.enter-exit-container-buttons{
     display: flex;
  justify-content: space-between;
  width: 43%;

}
.enter-exit-container-buttons button{
    /* width: 24%;
    height: 40px; */
  height: 40.2px;
 border-radius: 3px;
 background-color: white;
 font-size: 13px;
 width: 23%;
   border:.5px solid #ccc ;
}
.enter-exot-container-datefrom-dateto{
      width: 55.5%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  
}
.enter-exot-container-datefrom-dateto h6{
      margin: 0;
  display: flex;
  padding-bottom: 2px;
  align-items: center;
}
.workstation-container-main-section{
    height: calc(100vh - 270px);  
    display: flex;
          justify-content: space-between;
          flex-direction: column;
 
          overflow-y: auto

}
.refund-button{
    border: none;
    background-color: var(--main_color);
    /* background-color: rgb(255, 61, 61); */
    color: white;
    height: 40px;
    border-radius: 2px;
    width: 150px;
}
.refund-popup-container-inputs{
   width: 100%;

}
.refund-popup-container{
    width: 90%;
/* margin-top: 30px; */
    display: flex;
    flex: 1;
overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
}
.refund-popup-box{
  
    display: flex;
    flex-direction: column;

    justify-content: space-between;
}

.refund-popup-container-save-button{
    width: 100%; 

    height: 50px;
    
}
.refund-popup-container-save-button button{
    width: 100%;
    height: 45px;
    border: none;
    background-color:var(--main_color);
  
    color: var(--button_color);
    border-radius: 3px;
}
.refund-popup-container-refund-text{
    border-bottom: .5px solid grey;
    width: 90%;
    text-align: center;
    padding-bottom: 15px;
}
.debt-container-create-debt{
    /* border: 1px solid green; */
    height: 55px;
    background-color:none
}
.debt-container-main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height:  calc(100vh - 238px); 
  
}
.order-popup-container-refund-button{

   width: 100%;
margin-top: 25px;
}
.order-popup-container-refund-button button{
    border: none;
    background-color: var(--main_color);
    color: var(--button_color);
    width: 100%;
    height: 45px;
    border-radius: 2px;
    margin-bottom: 10px;
}
.dojo-edit-textarea{
    height: 45px;
    width: 100%;
    outline: none;
     border:1px solid grey ;
}
.dojo-edit-textarea:hover,.dojo-edit-textarea:focus{
    border: 1px solid var(--main_color);
    border-radius: 2%;
}
.order-container-search-box{
    margin-top: 25px;
    height: 40px;
border: 1px solid #d7d7d7;
margin-bottom: -20px;
height:50px;
display: flex;
align-items: center; 
justify-content: space-between;
padding-left: 8px;

}
.order-container-search-box input{
  height: 100%;
width: 97%;
border: none;
outline: none;
}
.container-drawer-icon{
    display: none;

}
.responsive-container-listitem-details{
    display: flex;
    /* justify-content: space-between; */
  
    width: 90%;
    align-items: center;
}

.responsive-container-listitem-details i{
    margin-right: 10px;
    cursor: pointer;
}
.stripe-container-button{

    margin-top: 25px;
    margin-bottom: -30px;
}
.stripe-container-button button{
    height: 40px;
    width: 120px;
    border-radius: 3px;
    border: none;
    color: white;
    background-color: var(--main_color);
}

.stripe-container-days{
    display: flex;
    width: 100%;

}
.second-screen-container-upload-image{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
   height: 100%;
   
    flex-direction: column;
}
.second-screen-container-image{
  /* width:100%;
  height: 330px;  */
margin-bottom: 20px;
} .second-screen-container-image p{
   display: flex;
   align-items: flex-start;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
    color: gray;
}
.second-screen-container-image img{
    height: 100%;
    width: 100%;
  
}
.second-screen-container-images-slider{
    width: 50%;
/* margin-top: 20px; */

}
.second-half-screen-container-images-slider{
      width: 60%;
        height: 490px; 
}

.second-full-screen-container-images-slider{
    width: 67%;
}
.second-screen-container{
    display: flex;
    
    justify-content: space-between;
   height: 100%;
  align-items: center;
padding-top: 10px;

}
.second-screnn-sidbar{
    width: 150px;
    padding-top: 10px;
/* border-right: .5px solid rgb(206, 210, 206); */
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.second-screnn-sidbar button{
    height: 40px;
    border: none;margin-bottom: 10px;
}
.second-screen-container-image-input{
    width:250px;
    margin-top: 10px;
}
.second-screen-container-images-list{
height: 100%;
display: flex;

flex-direction: column;
align-items: center;
justify-content: center;
}
.second-half-screen-container-images-list{
    height: 100%;
display: flex;

flex-direction: column;
align-items: center;
justify-content: center;
} 
.second-half-screen-container-images-list img{
   width:485px;
 height: 490px; 
  
}
.second-screen-container-images-list img{
 width:376px;
  height: 489px; 
 

}
.second-full-screen-container-images-list{
 height: 100%;
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
justify-content: center; 

}
.second-screen-carousel{

    width: 100%;
}
.second-full-screen-container-images-list img{ 
    width:530px;
  height: 297px; }
.second-screen-container-images-list div{
color: gray;
width: 100%;
display: flex;
height: 20px;
align-items: center;
justify-content: flex-end;
}
.second-screen-container-images-list i{
    cursor: pointer;
}
.second-screen-container-images-list i:first-child{
   margin-right: 6px;
}
.second-screen-container-send-and-chooseImage-buttons{
    display: flex;
    align-items: center;
    justify-content:center;
    width: 100%;
   
    margin-top: 10px;
}
.second-screen-container-upload-image span{
  font-size: 15px;
}
/* .second-screen-container-send-and-chooseImage-buttons button{
border: none;
background-color: var(--main_color);
    height: 50px;
    width: 49%;
    color: var(--button_color);
    border-radius: 3px;
 
    
} */
.second-screen-container-send-and-chooseImage-buttons div{
    width: 100%;
   
}
.second-screen-image-details-popup-container{
 width: 45%;
 height: 90%;
 overflow-y: auto;

}
.second-screen-image-popup-container{

display: flex;
justify-content: space-between;
flex-direction: column;
   width: 85%;
   /* height: 100%; */

}
.second-screen-image-popup-container p{
    text-align: center;
}
.second-screen-image-popup-box{
    display: flex;
    flex-direction: column;
 /* margin-top: 20px; */
height: 40%;
    justify-content: space-between;
}
.second-screen-image-popup-container-save-button{
    height: 50px !important;

}
.second-screen-image-popup-container-save-button button{
    background-color: var(--main_color);
    border: none;
    width: 100%;
    color: var(--button_color);
    height: 100%;border-radius: 3px;
}
.second-screen-image-popup-container-formgroup{

display: flex;
flex-direction: column;
justify-content: flex-start;
}
.second-screen-image-popup-container-contents{

    /* flex: 1; */
  
}
.tooltip-second-screen{
 width:5% !important;
 border: 1px solid green;
 z-index: 333333;
}
.public-second-screen-container-checkbox-selected-shop{
    border: 1px solid red;
 
}
.second-screen-container-noImage-button{

    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;

}
.second-screen-container-container-create-button{
    height: 50px;

    width: 100%;
    display: flex;
    align-items:flex-end;
    justify-content: flex-end;
}
.second-screen-container-noImage-button div:first-child{
    width: 100%;
    flex: 1;
  
    display: flex;
    justify-content: center;
    align-items: center;

}
.second-screen-container-container-create-button button{
    border: none;
    height: 40px;
    border-radius: 3px;
    background-color: var(--main_color);
    color: var(--button_color);
    width: 150px;
}
.second-screen-container-box-checkbox{

    height: 150px
}
.second-screen-container-settings{
      width: 50%;
display: flex;
align-items: center;
flex-direction: column;
justify-content: space-between;
  height: 491px; 
}
.second-half-screen-container-settings{
       width: 40%;
display: flex;
align-items: center;
flex-direction: column;
justify-content: space-between;
  height: 490px; 
}
.second-full-screen-container-settings{
        width: 30%;
display: flex;
align-items: center;
flex-direction: column;
justify-content: space-between;
  height: 491px; 

}
.second-screen-container-box-checkbox12{
   height: 70%;
    width: 50%;
   
}
.second-half-screen-container-box-checkbox12{
     height: 70%;
    width: 70%;
}
.second-full-screen-container-box-checkbox12{
    height: 70%;
    width: 90%;
}
.second-screen-container-checkbox-formGroup{
margin-top: 25px;
}
.second-screen-container-buttons{
  
       width: 100%;
       height: var(--button_height);;
       display: flex;
       justify-content: space-between;
}
.second-screen-container-buttons button{
    border: none;
    background-color: var(--main_color);
    color: var(--button_color);
    width: 49%;  border-radius: 3px;
}
.second-screen-container-buttons button:last-child{
    background-color: rgb(255, 43, 43);
}
.second-screen-container-all-buttons{
    width: 50%;
}
.second-half-screen-container-all-buttons{
    width: 70%;
}
.second-full-screen-container-all-buttons{
    width: 90%;
}
.second-screen-container-add-new-image{
    width: 100%;
  margin-top: 10px;
}
.second-screen-container-add-new-image button{
      height: var(--button_height);
      border: none;
      background-color: rgb(12, 180, 177);
      width: 100%;
      border-radius: 3px;
      color: var(--button_color);
}
/* .second-screen-container-create-button{

    width: 100%;
    height: var(--button_height);
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.second-screen-container-create-button button{
    width: 120px;
    height: 100%;
    background-color: var(--main_color);
    color: var(--button_color);
    border: none;
} */

.main-second-screen-container-shop-group-button{
    width: 100%;
    height: 40px;
  margin-top: 10px;
}
.main-second-screen-container-shop-group-button button{
width: 100%;
height: var(--button_height);
border: none;
background-color:#4585fc;
color: var(--button_color);
border-radius: 3px;
}
.main-second-screen-container{
    border: 1px solid blue;
    height: 100%;
}
.second-screen-contaoner-fullscreen-and-halfscreen-header{
  
   height: 60px;
    display: flex;
       padding-left: 10px;
       align-items: center;
         background-color: var(--main_navbar);
}
.second-screen-contaoner-fullscreen-and-halfscreen-header button{
  width: 170px;
    margin-right: 10px;
    height: 40px;

    margin-right: var(--button_normal_margin);
    background-color: var(--main_color);
    color: white;
    border-radius: var(--button_border_radius);
    border: solid var(--main_navbar_button_text_color);
}
.second-screen-contaoner-fullscreen-and-halfscreen-details{
   height: 100%;
   overflow-y: auto;
    margin-top: 25px;
}
.general-scond-screen{
     height:  calc(100vh - 230px); 
     overflow-y: auto;
}
.setting-second-screen{
    display: flex;
}
.table-popup-container-checkbox{

    margin:45px auto;
    margin-top: 55px;
}
.stripe-container-terminal-setting{
    border:.5px solid rgb(203, 202, 202);
    padding: 20px;
    padding-top: 30px;
    border-radius: 3px;
 margin-bottom: 50px;
}
.stripe-container-terminal-setting div{
margin-bottom: 5px;
}
.terminal-setting-text{
    color: gray;
    margin-bottom: 10px;
}
.general-setting-about-textarea{
    width: 100%;
    outline: none;
    padding-left: 1%;
min-height: 300px;
}
.general-privacy-policy-textarea{
      width: 100%;
    outline: none;
    padding-left: 1%;
min-height: 400px;
}
.general-setting-about-container{
    width: 100%;
}
.general-setting-about-textarea:hover,
.general-setting-about-textarea:focus,.general-privacy-policy-textarea:hover,.general-setting-about-textarea:focus {
    border: 1px solid var(--main_color);
}
.order_details_container{
 
justify-content: space-between;
    display: flex;
padding-top: 20px;
    height: 100%;
  max-height: calc(100vh - 210px);
    background-color:  #f8fafb;
  
 
}
.order_details_container_first_section{
    width: 35%; 
   background-color: white;
   overflow-y: auto;
          height: 100%;
   padding: 10px 20px;
 box-shadow: 3px 3px 5px 1px rgb(230, 230, 230), -2px -2px 5px 1px rgb(230, 230, 230); 
}
.order_details_container_second_section{
      width: 32%; 
overflow-y: auto;
/* height:  100%; */

 max-height: calc(100vh - 210px);
   background-color:  #f8fafb;
      background-color: white;
   padding: 10px 20px;
 box-shadow: 3px 3px 5px 1px rgb(230, 230, 230), -2px -2px 5px 1px rgb(230, 230, 230);
}
.order_details_container_third_section{
     width: 28%; 
    max-height: calc(100vh - 210px);
overflow-y: auto;
   background-color:  #f8fafb;
      background-color: white;
   padding: 10px 20px;
 box-shadow: 3px 3px 5px 1px rgb(230, 230, 230), -2px -2px 5px 1px rgb(230, 230, 230);
}
.order_details_container_first_section_header{
 height: 10%;
margin-bottom:10px;
 border-bottom: 1px solid rgb(192, 192, 192);
 display: flex;
 align-items: flex-end;

}
.order_details_container_first_section_header button{
    border: none;
    outline: none;
    width:90px;
    height: 40px;
      background: rgba(0,0,0,0);
margin-bottom: -1px;
    color: rgb(109, 108, 108);text-align: left;
}
.order_details_container_first_section_header button:first-child{
    width: 75px;
}
.order_details_container_first_section p{
    font-size: 16px;
    font-weight: 600;
}

.order_details_container_first_section_header h5{
    margin-bottom: 15px;
}
.order_details_container_third_section_refund_and_reset{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.order_details_container_third_section_refund_and_reset div{
    height: 45%;
}
.order_details_container_third_section_refund_and_reset button{
outline: none;
width: 100%;
height: 45px;
border: none;
border-radius: 3px;
color: white;
}
.order_details_container_third_section_refund_and_reset p{
    color: rgb(134, 133, 133);
}
.order_details_refund_payment_button{
background-color: rgb(58, 159, 58);
margin-bottom: 30px;
}
.order_details_refund_reset_order{
    background-color: rgb(230, 165, 24);
    margin-bottom: 10px;
}
.owners-menu-container-products-options{
    display: flex;
    justify-content: space-between;
    /* width: 42%; */

}
.owners-menu-container-products-options i{
    margin-left: 8px;
    margin-top: 3px;
}
.product-list-product-option-edit-box-container-option-title{
    width: 73%;
display: flex;
align-items: center;
    background-color: #dddddd;
    padding-left: 10px;
}
.setup-popup-cotainer-body{
flex: 1;
width: 90%;
display: flex;
justify-content: space-between;
flex-direction: column;
}
.coding-container-save-buttons{
    width: 90%;
    height: var(  --button_height);
    display: flex;
    justify-content: space-between;
}
.coding-container-save-buttons button{
    border: none;
    outline: none;
    width: 49%;
    background-color: var(--main_color);
    color: var(--button_color);
    border-radius: 3px;
}
.menu-list-title{
display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}
/* body{
    background-color:rgb(49, 49, 195);
} */
.external-company-container{
   
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--margin_menu_normal_horizontal);
    height: 97%;
    /* padding-bottom: 20px; */
} 
.external-company-container-title-and-logo{
    width: 30%;
height: 97%; 
border: .2px solid rgb(226, 227, 226);
padding: 0px 15px 10px 15px;
padding-top: 20px;
box-shadow: 1px 1px 2px rgb(230, 224, 224), -4px -3px 2px rgb(255, 251, 251);
display: flex;
flex-direction: column;
overflow-y: auto;
justify-content: space-between;
height: calc(100vh - 300px);
      
}
.external-company-container-shops-list{
    width: 66%;
height: 97%; 
border: .2px solid rgb(226, 227, 226);
overflow-y: hidden;
padding-top: 20px;
    box-shadow: 1px 1px 2px rgb(230, 224, 224), -4px -3px 2px rgb(255, 251, 251);
       height: calc(100vh - 300px)
}
.external-company-container-button button{
  width: 100%;
  height: var(--button_height);
  border: none;
  background-color: rgb(224, 69, 30);
  color: var(--button_color);
  border-radius: 3px;
}
.external-company-container-choose-image-box{
    /* border: 1px solid red; */
/* flex: 1; */

align-items:center;
display: flex;
flex-direction: column;
justify-content: flex-end;
height:80%;
margin-top: 30px;
/* height: calc(100vh - 400px); */

}
.external-company-container-image{
    width: 100%;
 height: 65%;

}
.external-company-container-image img{
    width: 100%;
    height: 100%;

}
.login-container-qrcode{
    text-align: center;
    margin-bottom: 0;
}
.login-container-qrcode img{
    height: 230px;
    width: 230px;
}
.login-container-qrcode h6{
    margin: 0;
    color: rgb(110, 109, 109);
    font-weight: 600;
}
.login-container-2factor-auth-code-input{
    width: 100%;
padding-top: 15px;
}
.login-container-2factor-auth-code-input h6{
        color: rgb(110, 109, 109);margin-bottom: 10px;
}
.login-container-2factor-auth-code-input input{
    width: 100%;
}

.chat-room {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.chat-message {
  font-size: 16px;
  margin-bottom: 10px;
}

.user-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.general-item-container-divs {
width: 42%;
height: 255px;


}
.general-item-container-divs div{
height: 85%;

}
.general-item-container-image-tag{
    margin-top: 28px;
}
.general-item-container-image-tag img{
     width: 100%;
height: 100%
}
.general-default-item-container-delet-image-icon{
    width: 25px;
                    height: 25px !important;
                    display: flex;
                    position: absolute;
                    border-radius: 100px;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(213, 209, 209, 0.7);
                    right: 3px;
                    top: 3px;
}

.general-item-container-choose-photo div{
height:62px;
}
.item-list-popup{
    width: 90%;
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-between;
    /* border: 2px solid black; */
}
.item-list-popup-container-all-section{
     background-color: white;
    height: 80%;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    position: relative;

    justify-content: space-between;
}
.item-list-popup-container-close-icon{
     position: relative;
    width: 90%;
    height:3%;
    padding: 0px 10px 9px 10px;
    align-items: center;
  
}
.item-list-popup-container-all-items{
    display: flex;
    justify-content: space-between;

  height:  calc(100% - 90px);
  padding: 0px 20px 0px 15px; position: relative;
}
.item-list-popup-container-any-item{
    width: 48%;

}
.item-list-popup-any-item{
    margin: 0 0 15px 0;
 
}
.item-list-popup-container-all-select-checkbox{
    width: 48%; 
      margin: 15px 0 15px 0;
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    padding: 0 0 0 var(--padding_menu_normal_horizontal);
    border-radius: var(--button_border_radius);
}
.item-list-popup-container-buttons{
    padding-top: 5px;
    border-top: 1px solid rgb(202, 202, 202);

    height:55px;
      display: flex;    justify-content: space-between;
}
.item-list-popup-container-buttons button{
    border: none;
    width: 49%;
  height: 100%;border-radius: 3px;

}
.shop-group-container-main{
 height: 78%;

 height: calc(100vh - 400px);
 padding-bottom: 10px;
 flex: 1;
}
.item-list-popup-container-buttons button:nth-of-type(2) {
background-color: var(--main_color);
color: var(--button_color);
}
.shop-group-container-submenu-dropdown{
    height: 45px;
    /* display: flex;
    justify-content: space-between; */
}
.shop-group-container-submenu-dropdown button{
    width: 28%;
    height: 43px;
     background-color: var(--main_color);
    color: var(--button_color);
      border: none;
}
.item-list-popup-container-no-item-text{
   width: 100%;
                      height: 100%;
                      align-items: center;
                      justify-content: center;
                      display: flex;
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      left: 0;
}
.delevery-area-popup-container-post-code{
    display: flex;
    width: 90%;
    margin: auto;

    justify-content: space-between;
}

.delevery-area-popup-container-postcode-and-edit{

    width: 50%;
}
.delevery-area-popup-container-postcode-and-edit button{
    border: none;
    width: 100%;
    height: 40px;
    background-color: var(--main_color);
    color: var(--button_color);
}

.delevery-area-popup-container-edit-box{
   border: 0.3px solid var(--main_navbar_button_text_color);
width: 100%;
display: flex;
justify-content: space-between;
height: 40px;
align-items: center;
}
.delevery-area-popup-container-edit-box span{
    max-width: 50%;

     height: 100%;
     align-items: center;
     display: flex;
     padding-left: 1%;
     
}
.delevery-area-popup-container-edit-box input{
    width: 70%;
    height: 100%;
    border: none;
    flex: 1;
    padding-left: 2%;
        outline: none;

}
.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

.App-map {
  height: 100%;
  width: 100%;
  margin: auto;
}

.web-setting-container-landing-page h5{
     color: grey;
    margin: 0px 0px 15px 0px
}
.container-area{
    height: 100%;
}
.delivery-area-container{
    width: 100%;
    height: 100%;
    height: calc(100% );

    display: flex;
    justify-content: space-between;
  
}
.delivery-area-container-map{
    width: 70%;
    height: 100%;
   
}
.deleivery-area-container-zones{
 box-shadow: 
    1px 1px 5px  rgba(151, 151, 151, 0.3), 
    -1px -1px 5px  rgba(151, 151, 151, 0.3);
  /* box-shadow: 10px 10px 5px 12px lightblue;
  box-shadow: 10px 10px 5px lightblue outset; */
width: 277px;
border: 1px solid rgb(220, 220, 220);
   height:100%;
padding: 20px 15px;
overflow-y: auto;
margin-right: 3px;
}
.containerStyle{
    width: 100%;
    height: 100%;

}

.deleivery-area-container-deliverytext-switch{
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.deleivery-area-container-deliverytext-switch h6{
    margin: 0;
}
.web-setting-container-landing-page h5{
     color: grey;
    margin: 0px 0px 15px 0px
}
.deleivery-area-container-any-zone{
border: 1px solid rgb(196, 196, 196);
border-radius: 5px;
margin: 10px 0px;
padding: 0px 10px;
}

.deleivery-area-container-header-of-details{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
height: 50px;
}
.deleivery-area-container-header-of-details div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.deleivery-area-container-header-of-details p{
    margin: 0;
    font-weight: 500;
    cursor: pointer;
}
.deleivery-area-container-header-of-details span{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--main_color);

    margin-right: 10px;
}
.deleivery-area-container-shape-buttons{
    display: flex;
    justify-content: space-between;
}
.deleivery-area-container-shape-buttons button{
    width: 50%;
    height: 40px;
    border: 1px solid var(--main_navbar_button_text_color);
    background-color: white;
    color: #5d5c5c;
    border-radius: 5px;
}
.deleivery-area-container-color-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;
}
.deleivery-area-container-color-box p ,.deleivery-area-container-zone-name p{
    margin: 0; width: 25%;
    font-size: 14px;
    color: rgb(87, 86, 86);
}
.deleivery-area-container-colors{

    height: 40px;
    width: 65%;
      border: 1px solid var(--main_navbar_button_text_color);
          border-radius: 5px;
}
.deleivery-area-container-color-selector{
    display: flex;
    justify-content: space-between;
    align-items: center;
width: 100%;
height: 100%;
}
.deleivery-area-container-color-display{

width: 28%;
  border-right: 1px solid var(--main_navbar_button_text_color);
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
}
.deleivery-area-container-color-display span{
width: 18px;
height: 18px;
}
.delivery-area-container-color-picker{
     position: absolute;
    top: 50px;
    left: 0px;
    right: 130px;
    z-index:100;
}
.deleivery-area-container-zone-name{
    display: flex;
    justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      align-items: center;
}
.deleivery-area-container-zone-name input{
    width: 65%;
    height: 40px;
    outline: none;
     border: 1px solid var(--main_navbar_button_text_color);
     border-radius: 5px;
}
.deleivery-area-container-delete-hide-icons{
flex: 1;
 /* height: 40px;
 display: flex;
 align-items: center; */
} .deleivery-area-container-delete-hide-icons i:nth-child(1){
    margin-right: 10px;
}
.deleivery-area-container-buttons {
    width: 60%;
    display: flex;
    justify-content: space-between;
    height: 40px;
    
}
.deleivery-area-cancel-button{
border: none;
text-decoration: underline;
background-color: transparent;
color: #525252;
font-weight: 500;
}

.deleivery-area-save-button{
   width: 50%;
background-color: #1ab11f;
border: none;
color: white;
      border-radius: 5px;
}
 .deleivery-area-cancel-button:hover{
    text-decoration: none;
}
.deleivery-area-container-addAnotherZone-text{
/* border: 1px solid blueviolet; */
margin-top: 20px;

}
 .deleivery-area-container-addAnotherZone-text button{
    text-decoration: underline;
        color: #3aca3f;
        font-weight: 500;
        border: none;
        background-color:transparent;
  
 }
  .deleivery-area-container-addAnotherZone-text button:hover{
         color: #35a339;
          text-decoration: none;
  }

.callerId-numbers-container-search-export-box{
       width: 71%;
       display: flex;
       justify-content: space-between;
       align-items:flex-end;
}
.callerId-numbers-container-search-box{
    width: 65%;
 border: 1px solid #d7d7d7;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.callerId-numbers-container-search-box i{
    color: gray;
    margin-left: 5px;
}
.callerId-numbers-container-search-box input{
  height: 100%;
    display: flex;
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
 
}
.callerId-numbers-container-search-box input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(183, 183, 183);
  opacity: 1; /* Firefox */
}

.callerId-numbers-container-buttons{
    width: 35%;
    display: flex;
    justify-content: space-between;
    height: 45px;
}
.callerId-numbers-container-buttons button:nth-child(1){
    width: 40%;
    height: 100%;
          border: none;
          background-color: var(--main_color);
          color: white;
}
.callerId-numbers-container-buttons button:nth-child(2){
    width:55%;
        height: 100%;
        border: none;
          color: white;
            background-color: rgb(51, 134, 250);
}
.caller-numbers-container{
    display: flex;
    justify-content: space-between;
    margin:20px 0px;
    height: 72px;

}
.callerid-numbers-container-main{
    border-top: 2px solid rgb(78, 78, 78);
    flex: 1;
    /* height: calc(100% - 115px); */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 10px !important;
  grid-gap:10px;
  padding: 10px 10px; 
  /* border: 1px solid rgb(116, 115, 115); */
}
.callerid-numbers-container-list{
    height:40px;
    border: 1px solid rgb(222, 222, 222);
    display: flex;
    justify-content: center;
    align-items: center;
background-color: #b7b6b6;
margin: 0;
}
.dashboard-refund-container-any-filter-section{
    width: 20%;

     display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.dashboard-refund-container-any-filter-section button{
    width: 49%;
 height: 40px;
background-color: white;
 border:1px solid rgb(202, 202, 202) ;
 border-radius: 3px;
}
.background-color-in-lists{
     width: 6px;
                height: 100%;
                margin-right: 3px;
                box-shadow: .7px .7px 1px #e1e2e3 ,-.7px -.7px 1px #e1e2e3
}

          .color-box {
            display: inline-block;
            position: relative;
         
        }

      
        .color-box::after {
            content: attr(data-title);
            visibility: hidden;
            opacity: 0;
            width: 135px;
            position: absolute;
            transform: translateX(-50%); 
            transition: opacity 0.3s;
            border-radius: 3px;
            pointer-events: none; 
            font-family: Arial, sans-serif;
            /* background-color:  #d71b06; */
              background: linear-gradient(45deg, #ae050b, #c234b1, #f169ca, #73a6ee, #2e63f6, #0a32fa);
                  /* background: linear-gradient(45deg, #b03a2e, #f0a89f, #e44634, #f39c12);  */
                   
            background-size: 300% 300%;
             animation: shine 4s linear infinite;
            color:     #fff;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 72px;
            z-index: 133333;
            /* top: 37px; */
            bottom: -20px;
            height: 25px;
            font-size: 14px;
         
        }

        .color-box:hover::after,
        .color-box:hover::before {
            visibility: visible;
            opacity: 1;
        }
         @keyframes shine {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }

 .fof-post-code-container-create-box{
                width: 50%;

                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              
        }
.fof-post-code-input-textbox{
    width: 76%;

}
.fof-post-code-container-buttons{
 width: 23%;
 height: 40px;
 display: flex;
 justify-content: space-between;
}
.fof-post-code-container-buttons button:nth-child(1){
   width:50px;
    height: 100%;
   
    border: none;
     background-color: white;
    border: 1px solid rgb(197, 197, 197);
}
.fof-post-code-container-buttons button:nth-child(2) {
     width: 50px;
    height: 100%;
    
    border: 1px solid rgb(197, 197, 197);
      background-color: white;
    color: black;

}
.fof-post-code-container-second-create-box{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
   padding-right: 30px;


}
.fof-post-code-container-first-create-box{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.meta-keywords-example-text{
font-size: 13px;
color: gray;
    }

    .svg-container {
            width: 60px; /* اندازه کوچک */
            height: 60px; /* اندازه کوچک */
            background-color: var(--main_color); /* پس‌زمینه قرمز */
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%; /* دایره‌ای کردن پس‌زمینه */
            position: absolute;
            right: 16px;
            bottom: -65px;
            z-index: 555555555;
            animation: moveUp 0.5s forwards, shake 0.3s infinite 3.5s;
            /* animation-delay: 3s;
            animation-iteration-count: infinite; */
        }
         @keyframes moveUp {
            to {
              bottom: 90px; /* مکان نهایی در پایین صفحه */
            }
        }
          @keyframes shake {
            0%, 100% {
                transform: translateX(0); 
            }
            20%, 60% {
                transform: translateX(-3px); 
            }
            40%, 80% {
                transform: translateX(3px); 
            }
        }
  .svg-container {
            animation: moveUp 1s forwards, shake 1s infinite 1s; 
            animation-delay: 0s, 10s; 
        }
       
        .svg-container svg path {
            fill: white; 
        }

.chat-container {   
     background-color: white;
  z-index: 555555555;
  width: 390px;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 20px;
  right: 20px;
  overflow: hidden; height: calc(100vh - 180px);
  border: 1px solid #ccc;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#31afc4;
  padding: 10px;
  color: black;
  height: 75px;
}

.profile {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-info {
  margin-left: 10px;
}

.close-chat-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 25px;
  color: black;
}

.chat-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #28b0c7;
  background-color: #f9f9f9;
  height: 70px;
}
.chat-footer div{
    width: 100%;
    border: 1px solid green;
    display: flex;
    justify-content: space-between;
    height: 40px;
    border: 1px solid #28b0c7;
     border-radius: 20px;
}
.chat-footer span{
    /* border: 1px solid blue; */
    width: 10%;
    color: #0ea8c2;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 6px 0px 5px;
}
/* .chat-footer span i:nth-child(1){
   transform: rotate(80deg)
} */
.message-input {
    /* border: 1px solid blue !important; */
    width: 88%;
    padding: 5px;
 outline: none;
background-color: transparent;
border: none
}

.message {
  position: relative;
  background-color: var(--main_color);
  padding: 10px 20px;
  border-radius: 20px;
  width: fit-content;
  max-width: 300px;
  color: white;
  font-size: 16px;

}
.container-message-in-right{
    width: 100%;
    display:flex;
    justify-content: flex-end;
}
.chat-box-main-container{
    overflow-y: auto;
    padding: 20px 0px;
    width: 100%;
  
}



.talk-bubble {
  margin:6px 20px;
  display: inline-block;
  position: relative;
  max-width:80%;
  height: auto;
  color: #43628c ;
  font-weight: 450;
  padding: 1em;
  background-color:#78c9d6 ;
  border-radius: 20px;
}

.triangle.left-top:after {
  content: ' ';
  position: absolute;
  transform: rotate(180deg);
  width: 0;
  height: 0;
  left: -15px;
  right: auto;
  top: auto;
  bottom:0px;
  border: 22px solid;
  border-color:#b9d5eb transparent transparent transparent;
  

}
.triangle.right-top:before {
    content: ' ';
    position: absolute;
    transform: rotate(180deg);
    width: 0;
    height: 0;
    left: auto;
    right: -15px;
    top:auto;
    bottom: 0px;
    border: 32px solid;
    border-color:#78c9d6 transparent transparent transparent;
}

